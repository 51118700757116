import { Button, Card, DatePicker, Form, Input, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/Api";
import { COMPRESS_IMAGE, POST } from "../../utils/apiRequest";
import ImagePicker from "../components/imagePicker";

const EditProfile = (props: any) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [idProofImage, setIdProofImage] = useState<any>();

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const upload = await COMPRESS_IMAGE(idProofImage?.file);
      const requestPayload = {
        id: props?.data?.id,
        first_name: values.first_name,
        last_name: values.last_name,
        mobile_number: values.mobile_number,
        mobile_number2: values.mobile_number2,
        birth_date: values.birth_date,
        place: values.place,
        email: values.email,
        photo: upload?.url,
      };

      const response: any = await POST(API.USER_UPDATE, requestPayload);

      if (response?.status) {
        message.success(response?.message || "Successfully updated");
        props?.reload();
      } else {
        message.error(response?.message || "Update failed");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("An error occurred while updating the profile");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (props?.data) {
      form.setFieldsValue({
        first_name: props?.data?.first_name || "",
        last_name: props?.data?.last_name || "",
        mobile_number: props?.data?.mobile_number || "",
        mobile_number2: props?.data?.mobile_number2 || "",
        birth_date: props?.data?.birth_date
          ? moment(props?.data?.birth_date)
          : null,
        place: props?.data?.place || "",
        email: props?.data?.email || "",
      });
    }
  }, [props?.data, form]);
  return (
    <Card className="col-12 col-lg-8">
      <h5 className="user-heading">Edit Profile</h5>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: "First name is required" }]}
            >
              <Input placeholder="Enter your first name" />
            </Form.Item>

            <Form.Item
              label="Mobile Number"
              name="mobile_number"
              rules={[
                { required: true, message: "Mobile number is required" },
                {
                  pattern: /^\d{10}$/,
                  message: "Enter a valid 10-digit mobile number",
                },
              ]}
            >
              <Input placeholder="Enter your mobile number" />
            </Form.Item>

            <Form.Item
              label="Birth Date"
              name="birth_date"
              rules={[{ required: true, message: "Birth date is required" }]}
            >
              <DatePicker
                placeholder="Select your birth date"
                className="w-100"
              />
            </Form.Item>

            <Form.Item label="User Image">
              <ImagePicker
                fileURL={idProofImage?.url || props?.data?.photo}
                onChange={setIdProofImage}
                aspectRatio={1 / 1}
                height="30vh"
              />
            </Form.Item>
          </Col>

          <Col md={6}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true, message: "Last name is required" }]}
            >
              <Input placeholder="Enter your last name" />
            </Form.Item>

            <Form.Item label="Alternate Mobile Number" name="mobile_number2">
              <Input placeholder="Enter your alternate mobile number" />
            </Form.Item>

            <Form.Item label="Place" name="place">
              <Input placeholder="Enter your place" />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Email is required" },
                { type: "email", message: "Enter a valid email address" },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
          </Col>
          <Col></Col>
          <Col className="mt-3">
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default EditProfile;
