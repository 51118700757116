import { ConfigProvider } from "antd";
import { Route, Routes } from "react-router-dom";
import Routing from "./navigation";
import ProtectedRoute from "./navigation/protectRouter";
import ThemeObj from "./theme";
import LoginScreen from "./website/loginScreen";
import { useSelector } from "react-redux";
import "./App.scss";

function App() {
  const User = useSelector((state: any) => state?.User);
  return (
    <ConfigProvider theme={ThemeObj}>
      <Routes>
        <Route index element={<LoginScreen />} />
        <Route index path="/login" element={<LoginScreen />} />
        <Route
          path="/Auth/*"
          element={
            <ProtectedRoute isSignedIn={User?.auth}>
              <Routing />
            </ProtectedRoute>
          }
        />
      </Routes>
    </ConfigProvider>
  );
}
export default App;
