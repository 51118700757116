import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

function DataTable({
  data,
  current,
  pageSize,
  total,
  onPageChange,
  deleteUser,
}: any) {
  const navigate = useNavigate();

  const columns = [
    {
      title: "S No",
      render: (_: any, __: any, index: number) =>
        (current - 1) * pageSize + index + 1,
    },
    {
      title: "Name",
      render: (data: any) => `${data?.first_name} ${data?.last_name || ""}`,
    },
    {
      title: "Admission No",
      dataIndex: "admisson_id",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Blood Group",
      dataIndex: "blood",
    },
    {
      title: "Subscription",
      dataIndex: "subscription_name",
    },
    {
      title: "Status",
      render: (data: any) => (
        <Tag color={data?.status ? "green" : "red"}>
          {data?.status ? "Active" : "Blocked"}
        </Tag>
      ),
    },

    {
      title: "Expire Date",
      dataIndex: "end_date",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      render: (item: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="View">
            <CiRead
              size={20}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => navigate(`/Auth/userDetails/${item?.id}`)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <CiEdit
              size={20}
              style={{ cursor: "pointer", color: "orange" }}
              onClick={() => navigate(`/Auth/createUser/${item?.id}`)}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              title="Delete the User"
              onConfirm={() => deleteUser(item?.id)}
              okText="Yes"
              cancelText="No"
            >
              <AiOutlineDelete
                size={20}
                style={{ cursor: "pointer", color: "red" }}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ x: true }}
      />
      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
      >
        <Pagination
          current={current}
          pageSize={pageSize}
          total={total}
          showSizeChanger
          onChange={(page, newPageSize) => onPageChange(page, newPageSize)}
          showTotal={(total) => `Total ${total} Users`}
        />
      </div>
    </div>
  );
}

export default DataTable;
