import { useNavigate, useLocation } from "react-router-dom";
import Menu from "./menu.json";
import DynamicIcon from "./dymanicIcon";
import Logo from "../assets/images/logo-dark.png";
import { IoPersonCircle } from "react-icons/io5";
import "./styles.scss";
import { useSelector } from "react-redux";
import { MdMenuOpen } from "react-icons/md";
import { useState } from "react";
import { IoIosArrowDropright } from "react-icons/io";

function SideBar(props: any) {
  const User = useSelector((state: any) => state?.User?.user);

  const [collapse, setCollapse] = useState(false);

  const collpseSidebar = () => {
    setCollapse(!collapse);
    props.doCollapse();
  };

  const navigate = useNavigate();
  let location = useLocation();

  const filteredMenu =
    User?.user?.type === "trainer"
      ? Menu.filter(
          (item) => item?.title !== "Transaction" && item?.title !== "Office"
        )
      : Menu;

  return (
    <div className={`dashboard-SideBar ${collapse ? "collapse-icon" : ""}`}>
      <div className="mt-2">
        <div
          className={`dashboard-SideBar-logoBox ${collapse ? "collapse-img" : ""}`}
        >
          <img src={collapse ? "" : Logo} alt="" />

          {collapse ? (
            <IoIosArrowDropright
              color="grey"
              size={25}
              style={{ cursor: "pointer" }}
              onClick={() => collpseSidebar()}
              className="collapse-close-icon"
            />
          ) : (
            <MdMenuOpen
              color="grey"
              style={{ cursor: "pointer" }}
              onClick={() => collpseSidebar()}
            />
          )}
        </div>

        <div>
          {filteredMenu.slice(0, 11).map((item: any) => {
            return (
              <div
                key={item?.id}
                onClick={() => navigate(item?.navigate)}
                className={
                  location.pathname === item?.navigate
                    ? "dashboard-SideBar-item active"
                    : "dashboard-SideBar-item"
                }
              >
                <div>
                  <DynamicIcon icon={item?.icon} size={22} color="#000000" />
                </div>
                <div className="text" style={{ whiteSpace: "nowrap" }} />
                <div className="sidebar-txt">{item?.title}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="dashboard-SideBar-profile"
        onClick={() => {
          if (User?.user?.type !== "trainer") {
            navigate("/Auth/officeDetails");
          }
        }}
      >
        <div>
          <IoPersonCircle size={40} color="#1b6ad5" />
        </div>

        {collapse ? null : (
          <div>
            <div className="dashboard-SideBar-head">{User?.office?.name}</div>
            {/* <div className="dashboard-SideBar-sub"></div> */}
          </div>
        )}
      </div>
    </div>
  );
}

export default SideBar;
