import { Button, Card, Form, Input, message, notification, Select } from "antd";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaSortAmountDownAlt, FaSortAmountUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBox from "../../components/loadingBox";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import DataTable from "./dataTable";
import UserFilter from "./UserFilter.json";

const UserScreen = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const params = useParams() 
  const [notifications, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [searchState, setSearchState] = useState({
    query: "",
    filterOption: params?.type,
    sortOrder: "DESC",
    page: 1,
    pageSize: 20,
  });

  const [meta, setMeta] = useState<any>({});
  const [dataState, setDataState] = useState({
    data: [],
  });

  const User = useSelector((state: any) => state?.User?.user);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const { query, filterOption, sortOrder, page, pageSize } = searchState;
      const req = {
        query,
        office_id: User?.office?.id,
        type: filterOption || params?.type,
      };
      const api = `${API.USER_FILTER}?order=${sortOrder}&page=${page}&take=${pageSize}`;
      const response: any = await POST(api, req);
      if (response?.status) {
        setDataState({
          data: response?.data || [],
        });
        setMeta(response?.meta);
      } else {
        setDataState((prev) => ({ ...prev, loading: false }));
        notifications.error({
          message: "Error",
          description: "Failed to fetch users.",
        });
      }
    } catch (error) {
      console.error(error);
      notifications.error({
        message: "Error",
        description: "An error occurred while fetching data.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (query: string) => {
    setSearchState((prev) => ({ ...prev, query, page: 1 }));
  };

  const handleFilterChange = (filterOption: any) => {
    setSearchState((prev) => ({ ...prev, filterOption, page: 1 }));
  };

  const toggleSortOrder = () => {
    setSearchState((prev) => ({
      ...prev,
      sortOrder: prev.sortOrder === "DESC" ? "ASC" : "DESC",
    }));
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setSearchState((prev) => ({
      ...prev,
      page,
      ...(pageSize && { pageSize }),
    }));
  };

  const clearFilters = () => {
    setSearchState({
      query: "",
      filterOption: "all",
      sortOrder: "DESC",
      page: 1,
      pageSize: 20,
    });
    form.resetFields();
  };

  const createOrEditUser = () => {
    navigate("/Auth/createUser");
  };

  useEffect(() => {
    fetchUsers();
  }, [searchState]);

  const DeleteUser = async (id: any) => {
    try {
      let deleteUrl = API.USERS_DELETE;
      let payload = {
        id: id,
        ofcId: User?.office?.id,
      };
      const userDelete: any = await POST(deleteUrl, payload);
      if (userDelete?.status) {
        message.success(userDelete?.message);
        fetchUsers();
      } else {
        message.error("something went wrong..!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <PageHeaders title="Users" breadcrumb="Users">
        <Form form={form} layout="inline">
          <div className="d-flex flex-column flex-wrap flex-sm-row gap-2">
            <Form.Item name="query">
              <Input
                style={{ width: 200 }}
                placeholder="Search name or phone etc ..."
                allowClear
                suffix={<CiSearch size={16} />}
                value={searchState?.query}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </Form.Item>

            <Form.Item>
              <Select
                showSearch
                placeholder="Select an option"
                optionFilterProp="children"
                onChange={handleFilterChange}
                value={searchState?.filterOption || params?.type}
                style={{ width: 200 }}
                allowClear
              >
                {UserFilter.map((item) => (
                  <Select.Option key={item.id} value={item.value}>
                    {item.text}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button onClick={toggleSortOrder}>
                {searchState?.sortOrder === "DESC" ? (
                  <FaSortAmountDownAlt />
                ) : (
                  <FaSortAmountUp />
                )}
              </Button>
            </Form.Item>
            <Button onClick={clearFilters}>Clear Filters</Button>

            <Form.Item>
              <Button type="primary" onClick={() => createOrEditUser()}>
                Create +
              </Button>
            </Form.Item>
          </div>
        </Form>
      </PageHeaders>

      <div className="screenBox">
        <Card>
          {isLoading ? (
            <LoadingBox />
          ) : (
            <DataTable
              data={dataState.data}
              current={searchState.page}
              pageSize={meta?.take}
              total={meta?.itemCount}
              onPageChange={handlePageChange}
              deleteUser={(id: any) => DeleteUser(id)}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default UserScreen;
