import { Button, Card, Form } from "antd";
import TextArea from "antd/es/input/TextArea";

const BroadCast = (props: any) => {

  
  const onFinish = (val:any) => {
    const phoneNumber = props?.data?.user?.mobile_number; 
    const message = val?.message; 
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div>
      <Card className="col-12 col-lg-5">
        <h5 className="user-heading">Broadcast Message</h5>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item label="Send Message" name="message">
            <TextArea placeholder="Message" />
          </Form.Item>
          <Button block htmlType="submit" type="primary">
            Send Message
          </Button>
        </Form>
      </Card>

    </div>
  );
};

export default BroadCast;
