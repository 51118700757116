import { Card } from "antd";

const TermsCondition = () => {
  return (
    <div>
      <Card className="mb-4">
        <h5 className="user-heading">Privacy Policy</h5>
        <p>
          Your privacy is of utmost importance to us. This Privacy Policy
          outlines how we collect, use, disclose, and protect your information
          when you use our mobile application.
        </p>
        <p>
          - We may collect personal information such as your name, email
          address, and phone number for account registration and subscription
          purposes.
        </p>
        <p>
          - Your data is securely stored, and we do not sell or share your
          personal information with third parties without your consent.
        </p>
        <p>
          - Usage analytics may be collected to improve our services and user
          experience.
        </p>
        <p>
          For more details, please refer to our full Privacy Policy document.
        </p>
      </Card>

      <Card className="mb-4">
        <h5 className="user-heading">Terms & Conditions</h5>
        <p>
          By accessing or using the XentryBook app, you agree to abide by and be
          bound by our Terms & Conditions. It covers the rules and regulations
          for using the app.
        </p>
        <p>
          - Users must be at least 18 years old to use the app or have parental
          consent.
        </p>
        <p>
          - Subscription fees are non-refundable once the service is activated.
        </p>
        <p>
          - Users are responsible for maintaining the confidentiality of their
          account credentials.
        </p>
        <p>
          For a complete set of rules, please refer to our full Terms &
          Conditions document.
        </p>
      </Card>

      <Card className="mb-4">
        <h5 className="user-heading">Subscription Details</h5>
        <p>
          XentryBook provides various subscription options, including yoga,
          bulking up, weight loss, cardio, etc. Users can choose their preferred
          programs, and gym owners can monitor and manage these subscriptions.
        </p>
        <p>
          - Subscriptions are billed on a monthly basis and automatically renew
          unless canceled.
        </p>
        <p>
          - Users can upgrade, downgrade, or cancel their subscriptions at any
          time through the app.
        </p>
        <p>
          - Gym owners have access to real-time analytics and user subscription
          data.
        </p>
        <p>
          For detailed subscription terms, please refer to our Subscription
          Agreement.
        </p>
      </Card>

      <Card className="mb-4">
        <h5 className="user-heading">Gym Owner Access</h5>
        <p>
          Gym owners have access to detailed subscription information to
          effectively manage user subscriptions. All personal information is
          handled securely and in accordance with our privacy policies.
        </p>
        <p>
          - Gym owners can view user profiles, subscription history, and contact
          users for updates or promotions.
        </p>
        <p>
          - User data shared with gym owners is limited to subscription details
          and does not include sensitive information.
        </p>
        <p>For more details, please refer to our Gym Owner Access Policy.</p>
      </Card>

      <Card className="mb-4">
        <h5 className="user-heading">User Responsibilities</h5>
        <p>
          Users are responsible for maintaining the security of their accounts
          and passwords. Sharing login credentials with others is strictly
          prohibited. Users must notify us immediately of any unauthorized
          access or suspicious activities on their accounts.
        </p>
      </Card>

      <Card className="mb-4">
        <h5 className="user-heading">Contact Information</h5>
        <p>
          If you have any questions or concerns regarding our Policies, Terms &
          Conditions, please contact us at support@Xentrybook.com.
        </p>
      </Card>
    </div>
  );
};

export default TermsCondition;
