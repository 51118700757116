import { Button, Card, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CiCalendarDate } from "react-icons/ci";
import { GiPlainCircle } from "react-icons/gi";
import { MdOutlineSubtitles } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { GET, PUT } from "../../utils/apiRequest";
import CustomModal from "../components/customModal/customModal";
import LoadingBox from "../../components/loadingBox";

const SubscriptionDetails = () => {
  const [data, setData] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const id = useParams();
  const navigate = useNavigate();
  const User = useSelector((state: any) => state?.User?.user);

  const showDeleteConfirmation = () => {
    setIsModalOpen(true); 
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    getDetails();
    getUsers();
  }, []);

  const deleteSub = async () => {
    try {
      setIsLoading(true);
      let api = `${API.SUBCRIPTION_DELETE_SOFT}${id?.id}`;
      const del: any = await PUT(api, {});
      if (del?.status) {
        setIsLoading(false);
        message.success("Succefully Deleted Subscription");
        navigate("/Auth/subscriptionDetails");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getDetails = async () => {
    try {
      const details: any = await GET(API.SUBCRIPTION + id?.id, {});
      if (details?.status) {
        setData(details?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      let api = `${API.SUBCRIPTION_USERS}${User?.office?.id}/${id?.id}?order=DESC&page=1&take=50`;
      const details: any = await GET(api, {});
      if (details?.status) {
        setUsers(details?.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <PageHeaders title={"Subscription"} breadcrumb="Subscription Details">
        <Button
          type="primary"
          onClick={() => navigate(`/Auth/createSubscription/${id?.id}`)}
        >
          Edit
        </Button>
      </PageHeaders>
      <div className="screenBox">
        <Card  className="col-12 col-lg-5">
         
          <Row>
            <h5 className="user-heading">Subscription Details</h5>
            <Col md={6}>
            <div>
              <MdOutlineSubtitles size={20} className="me-2"/>
              <span> Subscription : {data?.name}</span>
              </div>
              <br />
              <div>
              <CiCalendarDate size={20} className="me-2"/>
              <span>
                Created Date : {moment(data?.createdAt).format("DD/MM/YYYY")}
              </span>
            </div>
         
            </Col>
            <Col md={6} className="text-start text-sm-end">
            <div>
              {data?.price} / {data?.duration} Year -
              <span> {data?.time} hrs / day</span>
              </div>
            <br/>
            <div>
              <Button className="text-danger" onClick={showDeleteConfirmation}>
                Delete
              </Button>
            </div></Col>
          </Row>
        </Card>
        <br />
        
       {users?.length ? <h6>Users ({users?.length})</h6>:null} 
        {isLoading ? (
          <LoadingBox />
        ) : (
          users?.map((item: any) => (
            <Card
              key={item.id}
              className="col-12 col-lg-5 mb-2"
              onClick={() => navigate(`/Auth/userDetails/${item.id}`)}
              style={{ cursor: "pointer" }}
            >
              
              <Row>
                <Col md={2}>
                  <div>
                    <img
                      src={item.photo}
                      alt={item.name}
                      className="rounded-circle me-3"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <div>
                      {item.first_name} {item?.last_name}
                    </div>
                    <div> {item?.admisson_id}</div>
                    <div>
                      Expired On : {moment(item?.end_date).format("DD/MM/YYYY")}
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-end">
                    {item.subscription_name}
                    <br />
                    <br />
                    <GiPlainCircle color={item?.status ? "#31d467" : "red"} />
                  </div>
                </Col>
              </Row>
            </Card>
          ))
        )}
      </div>
      {isModalOpen ? (
        <CustomModal
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          onDelete={deleteSub}
          title="Subscription"
          type="Delete"
        />
      ) : null}
    </div>
  );
};

export default SubscriptionDetails;
