import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export const options = {
  responsive: true,
  borderRadius: 100,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: { display: false },
  },
  barPercentage: 0.2,
};

export default function GraphChart(props: any) {
  const { data: rawData } = props;

  const labels = rawData?.map((item: any) => item?.label);
  const values = rawData?.map((item: any) => item?.value);

  const data = {
    labels, 
    datasets: [
      {
        label: "Membership Plans",
        data: values,
        borderColor: "#1b6ad5",
        backgroundColor: "#1b6ad5",
      },
    ],
  };

  return (
    <div className="Dashboard-ViewChart">
      <div style={{ height: 500, overflow: "hidden" }}>
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}
