import { Button, Card, Form, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { GET, PUT } from "../../utils/apiRequest";
import HorizontalLineLoader from "../components/loader";
import SubscriptionDataTable from "./dataTable";
import LoadingBox from "../../components/loadingBox";

function SubscriptionScreen() {
  const navigate = useNavigate();
  const [form] = useForm();
  const [Notifications, contextHolder] = notification.useNotification();
  const User = useSelector((state: any) => state?.User?.user);
  const createOrEditBooking = (booking: any) => {
    navigate(`/Auth/createSubscription`);
  };
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>();
  const [pageSize, setpageSize] = useState<any>(10);

  useEffect(() => {
    getSubscriptionAll();
  }, [page, pageSize]);

  const handlePageChange = (page: number, take: number) => {
    setPage(page);
    setpageSize(take);
  };

  const getSubscriptionAll = async () => {
    try {
      let api = `${API.SUBCRIPTION_ALL}${User?.office?.id}`;
      const response: any = await GET(api, null);
      if (response?.status) {
        setData(response?.data);
        setMeta(response?.meta);
        setLoading(false);
      } else {
        setLoading(false);
        console.error(response?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const deleteSub = async (id: number) => {
    try {
      setLoading(true);
      let api = `${API.SUBCRIPTION_DELETE_SOFT}${id}`;
      const del: any = await PUT(api, {});
      if (del?.status) {
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div>
      {contextHolder}
      <PageHeaders title={"Subscription"} breadcrumb="Subscription">
        <Form form={form}>
          <div className="d-flex flex-column flex-wrap flex-sm-row gap-2">
            {/* <Form.Item name={"query"} noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search"
                allowClear
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item> */}
{/* 
            <Form.Item name={"date"} noStyle>
              <DatePicker placeholder="Create Date" style={{ minWidth: 100 }} />
            </Form.Item> */}
            <Form.Item noStyle>
              <Button onClick={() => createOrEditBooking({})} type="primary">
                Create +
              </Button>
            </Form.Item>
          </div>
        </Form>
      </PageHeaders>
      {loading ? <HorizontalLineLoader /> : null}
      <div className="screenBox">
        <Card>
          {loading ? (
            <LoadingBox />
          ) : (
            <SubscriptionDataTable
              data={data}
              page={page || 1}
              current={page || 1}
              pageSize={pageSize}
              onPageChange={handlePageChange}
              total={meta?.itemCount || 0}
              delete={(id: number) => deleteSub(id)}
            />
          )}
        </Card>
      </div>
    </div>
  );
}

export default SubscriptionScreen;
