import { Pagination, Table, Tooltip } from "antd";
import { CiRead } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

interface DueDataTableProps {
  data: any[];
  page: number;
  pageSize: number;
  total: number;
  onPageChange: (page: number, pageSize?: number) => void;
}

const DueDataTable = ({
  data,
  page,
  pageSize,
  total,
  onPageChange,
}: DueDataTableProps) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "S No",
      key: "serial",
      width: 50,
      render: (_: any, __: any, index: number) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Image",
      dataIndex: "photo",
      key: "photo",
      width: 80,
      render: (photo: string | null) =>
        photo ? (
          <img
            src={photo}
            alt="User"
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        ) : (
          <span>No Image</span>
        ),
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Admission ID",
      dataIndex: "admisson_id",
      key: "admisson_id",
    },
    {
      title: "Total Due",
      dataIndex: "total_due",
      key: "total_due",
    },
    {
      title: "Action",
      key: "action",
      render: (item: any) => (
        <Tooltip title="View">
          <CiRead
            size={20}
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => navigate(`/Auth/userDetails/${item?.user_id}`)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={(record) => record?.user_id || Math.random().toString()}
        scroll={{ x: true }}
      />
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
        <Pagination
          current={page}
          pageSize={pageSize}
          total={total}
          showSizeChanger
          showTotal={(total) => `Total ${total} Transactions`}
          onChange={onPageChange}
          responsive
        />
      </div>
    </>
  );
};

export default DueDataTable;
