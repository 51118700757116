import { Pagination, Table, Tag, Tooltip } from "antd";
import { CiRead } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

function DataTable({
  data,
  current,
  pageSize,
  total,
  onPageChange,
  filter,
}: any) {
  const navigate = useNavigate();

  const columns = [
    {
      title: "Name",
      render: (data: any) => `${data?.first_name} ${data?.last_name || ""}`,
    },
    {
      title: "Admission No",
      dataIndex: "admisson_id",
    },
    {
      title: "Admission No",
      render: () => (
        <Tag color={filter === "Presents" ? "green" : "red"}>{filter}</Tag>
      ),
    },
    {
      title: "Action",
      render: (item: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="View">
            <CiRead
              size={20}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => navigate(`/Auth/userDetails/${item?.id}`)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        dataSource={data}
        columns={columns}
        size="small"
        scroll={{ x: true }}
      />
    </div>
  );
}

export default DataTable;
