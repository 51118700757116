import { Button, notification } from "antd";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../../../config/Api";
import { POST } from "../../../../utils/apiRequest";
import PauseResumeModal from "./pauseResumeModal";
import { IoPauseCircleOutline } from "react-icons/io5";

const PauseScreen = ({ data, getUser }: any) => {
  const holdResume = data?.holdResume;
  const user = useSelector((state: any) => state?.User?.user);
  const [activeModal, setActiveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showModal = () => {
    setActiveModal(true);
  };

  const closeModal = () => {
    setActiveModal(false);
  };

  const submit = async (from: any, to: any, days: any) => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: data?.user?.id,
        current_exp: data?.end_date,
        hold_from: from?.format(),
        hold_to: to?.format(),
        num_days: days,
        type: holdResume?.id ? "resume" : "pause",
        office_id: user?.office?.id,
        new_exp: holdResume?.id
          ? moment(data?.end_date).add(days, "days").format()
          : null,
      };

      const response: any = await POST(API.HOLD_RESUME_ADD, payload);

      if (response?.status) {
        notification.success({
          message: "Success",
          description: holdResume?.id
            ? "Subscription resumed successfully."
            : "Subscription paused successfully.",
        });
        getUser();
        setIsLoading(false);
        closeModal();
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <div>
      <Button
        className={holdResume?.id ? "text-success" : "text-warning"}
        onClick={showModal}
        style={{ height: holdResume?.id && "60px" }}
      >
        <IoPauseCircleOutline className="me-2" size={18} />
        <span>{holdResume?.id ? "Resume" : "Pause"} Subscription</span>
        {holdResume && (
          <div>
            {`Hold period ${moment(holdResume?.hold_from).format(
              "DD MMMM"
            )} to ${moment(holdResume?.hold_to).format("DD MMMM")} - ${
              holdResume?.num_days
            } days`}
          </div>
        )}
      </Button>
      {activeModal && (
        <PauseResumeModal
          data={data}
          isModalOpen={activeModal}
          handleCancel={closeModal}
          onSubmit={submit}
          holdResume={holdResume}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default PauseScreen;
