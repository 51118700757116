import { Pagination, Table, Tooltip } from "antd";
import moment from "moment";
import { CiRead } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

function TransactionDataTable({
  data,
  page,
  pageSize,
  total,
  onPageChange,
}: any) {
  console.log('===========TransactionDataTable=========================');
  console.log(data);
  console.log('===========TransactionDataTable=========================');
  const navigate = useNavigate()
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      width:50,
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width:120,
    },
    {
      title: "Paid Amount",
      dataIndex: "paid_mount",
      key: "paid_mount",
      width:120,
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      width:150,
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width:120,
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      width:120,
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      render: (item: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="View">
            <CiRead
              size={20}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => navigate(`/Auth/userDetails/${item?.user_id}`)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={(record) => record?.id || Math.random()}
        scroll={{ x: true }}
      />
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}
      >
        <Pagination
          current={page}
          pageSize={pageSize}
          total={total}
          showSizeChanger
          showTotal={(total) => `Total ${total} Transactions`}
          onChange={(current, size) => onPageChange(current, size)}
          responsive
        />
      </div>
    </>
  );
}

export default TransactionDataTable;
