import { Button, Card, Form, Input, message, Select } from "antd";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { GET, POST, PUT } from "../../utils/apiRequest";
import duration from "../../utils/durations.json";
function CreateSubscription() {
  const id = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const User = useSelector((state: any) => state?.User?.user);
  const [isLoading, setIsLoading] = useState(false);
  const SubId = id?.id;

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      let obj = {
        name: val?.name,
        price: Number(val?.price) || null,
        duration: Number(val?.duration) || null,
        duration_type: val?.duration_type,
        time: val?.time,
        Office_id: Number(User?.office?.id),
      };
      let url = SubId ? API.SUBCRIPTION + SubId : API.SUBCRIPTION_ADD;
      let method = SubId ? PUT : POST;
      const response: any = await method(url, obj);
      if (response?.status) {
        message.success(
          `${SubId ? "Successfully Updated " : "Successfully Created"}`
        );
        navigate("/Auth/subscriptionDetails");
      } else {
        message.success(response?.message || "Something went wrong");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getSubscription = async () => {
    try {
      const response: any = await GET(API.SUBCRIPTION + SubId, null);
      if (response?.status) {
        form.setFieldsValue({
          price: response?.data?.price,
          name: response?.data?.name,
          duration: response?.data?.duration,
          duration_type: response?.data?.duration_type,
          time: response?.data?.time,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSubscription();
  }, [SubId]);

  return (
    <div>
      <PageHeaders
        title={SubId ? "Subscription" : "Subscription"}
        breadcrumb={SubId ? "Update Subscription" : "Create Subscription"}
      />
      <div className="screenBox">
        <Card>
          <h5 className="user-heading">Subscription Details</h5>
          <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            hideRequiredMark
          >
            <Row>
              <Col md={4}>
                <Form.Item
                  name={"name"}
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the field",
                    },
                  ]}
                >
                  <Input placeholder="Eg: Classic" />
                </Form.Item>

                <Form.Item
                  name="duration_type"
                  label="Payment Method"
                  rules={[{ required: true, message: "Choose Duration Type" }]}
                >
                  <Select placeholder="Choose Duration Type">
                    {duration.map((method, idx) => (
                      <Select.Option value={method} key={idx}>
                        {method}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item
                  name={"price"}
                  label="Price"
                  rules={[
                    {
                      required: true,
                      message: "Please input the price",
                    },
                  ]}
                >
                  <Input placeholder="Eg: 200" />
                </Form.Item>
                <Form.Item
                  name={"time"}
                  label="Daily Time (hrs/day)"
                  rules={[
                    {
                      required: true,
                      message: "Please input the time",
                    },
                  ]}
                >
                  <Input placeholder="Eg: 2" />
                </Form.Item>
              </Col>
              <Col md={4}>
                <Form.Item
                  name={"duration"}
                  label="Duration"
                  rules={[
                    {
                      required: true,
                      message: "Please input the duration",
                    },
                  ]}
                >
                  <Input placeholder="Eg: 3" />
                </Form.Item>
              </Col>
            </Row>

            <br />
            <Row>
              <Col sm={4}></Col>
              <Col sm={4}>
                <Button size="large" danger block onClick={() => navigate(-1)}>
                  Close
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {SubId ? "Update" : "Create"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default CreateSubscription;
