import { Button, Card, Form, Input, message } from "antd";
import { useState } from "react";
import { POST } from "../../utils/apiRequest";
import { API } from "../../config/Api";

const ContactUs = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: any) => {
    let payload = {
      name: values.name,
      email: values?.email,
      phone: values?.phone,
      message: values?.message,
    };
    setIsLoading(true);
    try {
      const response: any = await POST(API.CONTACT_US, payload);

      if (response?.status) {
        message.success("Message sent successfully!");
        form.resetFields();
      } else {
        message.error("Failed to send message. Please try again.");
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Card className="col-12 col-lg-5">
        <h5 className="user-heading">Contact Us</h5>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label="Full Name"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input placeholder="Eg: Jhone Honai" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email Address"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input placeholder="Eg: honai@gmail.com" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input placeholder="Eg: +91 123456789" />
          </Form.Item>

          <Form.Item
            name="message"
            label="Your Message"
            rules={[{ required: true, message: "Please input your message!" }]}
          >
            <Input.TextArea rows={5} placeholder="Eg: My issue is . . . ." />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading} block>
              Send Message
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ContactUs;
