// import { Badge, Tag } from "antd";
// import moment from "moment";
// import { FaRegUserCircle } from "react-icons/fa";
// import { GiPlainCircle } from "react-icons/gi";
// import NoData from "../../components/noData";
// function ExpiringUser(props: any) {
//   const isExpired = (date: string) => {
//     try {
//       let end_date = moment(date);
//       let today = moment();
//       return today.isSameOrAfter(end_date, "D");
//     } catch (err) {
//       console.log(err);
//     }
//   };
//   return (
//     <>
//       <div className="dashboard-Txt2">
//         <div> Expiring in 5 days</div>
//         <div>{props?.data?.length}</div>
//       </div>
//       {props?.data?.length ? (
//         props?.data.map((item: any, index: number) => (
//           <Badge.Ribbon
//             key={index}
//             text={item?.type === "trainer" ? "Trainer" : ""}
//             color={item?.type === "trainer" ? "#1b6ad5" : "transparent"}
//           >
//             <div className="Dashboard-booking">
//               <div className="Dashboard-cardBox1">
//                 {item?.photo ? (
//                   ""
//                 ) : (
//                   <FaRegUserCircle
//                     style={{
//                       fontSize: "40px",
//                       borderRadius: 5,
//                       color: "grey",
//                     }}
//                   />
//                 )}
//               </div>
//               <div style={{ flex: 1 }}>
//                 <div>
//                   {item?.first_name} {item?.last_name}
//                 </div>
//                 <div>{item?.admisson_id}</div>
//                 <div
//                   style={{ display: "flex", justifyContent: "space-between" }}
//                 >
//                   <div>
//                     {`${
//                       isExpired(props?.item?.end_date) ? "Expired" : "Expire"
//                     } on ${moment(props?.item?.end_date).format("DD/MM/YYYY")}`}
//                   </div>
//                   <div>
//                     <GiPlainCircle
//                       color={
//                         item?.HoldResume?.id
//                           ? "orange"
//                           : item?.status
//                             ? "#31d467"
//                             : "red"
//                       }
//                     />
//                   </div>
//                 </div>
//                 <Tag color="">{item?.subscription_name}</Tag>
//               </div>
//             </div>
//           </Badge.Ribbon>
//         ))
//       ) : (
//         <div>
//           <NoData />
//         </div>
//       )}
//     </>
//   );
// }

// export default ExpiringUser;
import { Badge, Tag } from "antd";
import moment from "moment";
import { GiPlainCircle } from "react-icons/gi";
import NoData from "../../components/noData";
import { useNavigate } from "react-router-dom";
function ExpiringUser(props: any) {
  const navigate = useNavigate()
  const isExpired = (date: string) => {
    try {
      let end_date = moment(date);
      let today = moment();
      return today.isSameOrAfter(end_date, "D");
    } catch (err) {
      console.log(err);
    }
  };
console.log(props?.data,"props?.data");

  return (
    <div >
      <div className="dashboard-Txt2">
        <div>{ props?.type}</div>
        <div>{props?.total}</div>
      </div>
      {props?.data?.length ? (
        props?.data.map((item: any, index: number) => (
          <Badge.Ribbon
            key={index}
            text={item?.type === "trainer" ? "Trainer" : ""}
            color={item?.type === "trainer" ? "#1b6ad5" : "transparent"}
          >
            <div className="Dashboard-booking" onClick={()=>navigate(`/Auth/userDetails/${item?.id}`)}>
              <div>
                <img
                  src={item?.photo}
                  alt={item?.name}
                  className="rounded-circle me-3"
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
              <div style={{ flex: 1 }}>
                <div className="fs-5">
                {item?.first_name} {item?.last_name}
                </div>
                <div className="text-secondary mb-2">{item?.admisson_id}</div>
                <div className="d-flex justify-content-between"
                >
                  <div className="mb-2">
                    {`${
                      isExpired(props?.item?.end_date) ? "Expired" : "Expire"
                    } on ${moment(props?.item?.end_date).format("DD/MM/YYYY")}`}
                  </div>
                  <div>
                    <GiPlainCircle
                      color={
                        item?.HoldResume?.id
                          ? "orange"
                          : item?.status
                            ? "#31d467"
                            : "red"
                      }
                    />
                  </div>
                </div>
                <Tag >{item?.subscription_name}</Tag>
              </div>
            </div>
          </Badge.Ribbon>
        ))
      ) : (
        <div>
          <NoData />
        </div>
      )}
    </div>
  );
}

export default ExpiringUser;

