import { Button, Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { IoLogoWhatsapp } from "react-icons/io";

const WhatsAppModal = (props: any) => {
    const onFinish = (val:any) => {
        const phoneNumber = props?.phone; 
        const message = val?.message; 
        const encodedMessage = encodeURIComponent(message);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappUrl, '_blank');
      };

  return (
    <Modal
      open={props?.isModalOpen}
      onCancel={props?.handleCancel}
      footer={null}
      closable={false}
      centered
    >
            <h5 className="user-heading">Send Message To ({props?.name})</h5>
            <br/>
         <div>
          <Form layout="vertical" onFinish={onFinish} initialValues={{message: props?.message}}> 
            <Form.Item name={"message"} label="Message"><TextArea rows={6}/></Form.Item>
            <div className="d-flex gap-2">
          <Button block onClick={props?.handleCancel}   >
              Cancel
          </Button>
          <Button  className="text-success" htmlType="submit">
            <IoLogoWhatsapp className="me-2"/>
              Send WhatsApp Message
              </Button>
            </div>
          </Form>
     
         </div>
    </Modal>
  );
};

export default WhatsAppModal;
