import { Button, DatePicker, Form, Input, message, Modal, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { API } from "../../../config/Api";
import { GET, POST } from "../../../utils/apiRequest";
import { FindDateRange } from "../../../utils/dateRange";
const RenewSubscriptionModal = (props: any) => {
  const User = useSelector((state: any) => state?.User?.user);
  const [isLoading, setloading] = useState(false);
  const [selectedSub, setSelectedSub] = useState<any>(null);
  const [duration, setDuration] = useState<any>(null);
  const [durationType, setDurationType] = useState<any>(null);
  const [start_date, setStart_date] = useState<any>(null);
  const [end_date, setEnd_date] = useState<any>(null);
  const [price_amount, setPriceAmount] = useState<number>(0);
  const [subscription, setSubscription] = useState<any>([]);

  const [form] = Form.useForm();

  const onFinish = async (val: any) => {
    try {
      setloading(true);
      const url = API.TRANSACTION_ADD;
      let obj = {
        type: props?.data?.type,
        office_id: props?.data?.office_id,
        user_id: props?.data?.id,
        subscription_id: val?.subscription,
        subscription_name: selectedSub.name,
        user_name: `${props?.data?.first_name} ${props?.data?.last_name}`,
        amount: price_amount?.toString(),
        paid_mount: val?.paid_amount?.toString(),
        payment_method: val?.payment_method,
        comment: val?.comment,
        start_date: start_date,
        end_date: end_date,
        by: `${User?.first_name} ${User?.last_name}`,
      };
      const response: any = await POST(url, obj);
      if (response?.status) {
        message.success("Successfully renew subscription");
        props?.reload();
        props?.handleCancel();
        setloading(false);
      } else {
        message.error(response?.message || "Failed to renew subscription");
        setloading(false);
      }
    } catch (error) {
      setloading(false);
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const handleSubscriptionChange = async (value: any, option: any) => {
    try {
      setSelectedSub(option);
      const { duration_type, duration, price } = option;
      setDuration(duration);
      setDurationType(duration_type);
      const ranges: any = await FindDateRange(duration_type, duration);
      setStart_date(ranges.start);
      setEnd_date(ranges.end);
      setPriceAmount(price);
    } catch (err) {
      console.error("Error selecting subscription:", err);
    }
  };
  const ChangeSubDate = (start: any, end: any, type: any) => {
    try {
      if (!start || !end) {
        setStart_date(null);
        setEnd_date(null);
        return;
      }
      setStart_date(start);
      setEnd_date(end);
      const duro = duration || 0;
      if (type === 1) {
        let endDate = moment(start).add(duro, durationType);
        setEnd_date(endDate);
        form.setFieldsValue({ endDate: endDate });
      } else {
        let startDate = moment(end).subtract(duro, durationType);
        setStart_date(startDate);
        form.setFieldsValue({ startDate: startDate });
      }
    } catch (err) {
      console.log("Error in ChangeSubDate:", err);
    }
  };
  const getDetails = async () => {
    try {
      const details: any = await GET(
        `${API.SUBCRIPTION_OFFICE}${User?.office?.id}`,
        {}
      );
      if (details?.status) {
        setSubscription(details?.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  useEffect(() => {
    getDetails();
  }, []);

  return (
    <div>
      <Modal
        open={props.isModalOpen}
        onOk={props.isModalOpen}
        onCancel={props.handleCancel}
        footer={false}
        closeIcon={false}
        centered
      >
        <h5 className="user-heading">
          Renew Subscription ({props?.data?.admisson_id})
        </h5>
        <Form layout="vertical" form={form} onFinish={onFinish} hideRequiredMark>
          <Row>
            <Col md={6}>
              <Form.Item name="subscription" label="Subscription Plan" required>
                <Select
                  placeholder="Choose a Subscription Plan"
                  onChange={handleSubscriptionChange}
                >
                  {subscription?.map((sub: any) => (
                    <Select.Option
                      key={sub.id}
                      value={sub.id}
                      duration={sub.duration}
                      duration_type={sub.duration_type}
                      price={sub.price}
                      name={sub.name}
                    >
                      {sub.name} - {sub.duration} {sub.duration_type} - ₹
                      {sub.price}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="startDate" label="Start Date" required>
                <DatePicker
                  style={{ width: "100%" }}
                  value={start_date && moment(start_date).isValid() ? moment(start_date) : null}
                  onChange={(value) => ChangeSubDate(value, end_date, 1)}
                />
              </Form.Item>
              <Form.Item label="Amount">
                <Input value={price_amount} disabled />
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="payment_method"
                label="Payment Method"
                rules={[{ required: true, message: "Choose Payment Method" }]}
              >
                <Select placeholder="Choose Payment Method">
                  {["BANK", "UPI", "CARD", "CASH"].map((method, idx) => (
                    <Select.Option value={method} key={idx}>
                      {method}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="endDate" label="End Date">
                <DatePicker
                  style={{ width: "100%" }}
                  value={end_date && moment(end_date).isValid() ? moment(end_date) : null}
                  onChange={(value) => ChangeSubDate(start_date, value, 2)}
                />
              </Form.Item>
              <Form.Item label="Paying Amount" name="paid_amount">
                <Input type="number" min={0} max={price_amount} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="comment" name="comment">
            <Input placeholder="New Plan Update etcc..." />
          </Form.Item>
          <Row>
            <Col md={5}></Col>
            <Col md={7}>
              <div className="d-flex gap-2">
                <Button block onClick={() => props?.handleCancel()}>
                  Close
                </Button>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Update Now
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default RenewSubscriptionModal;
