import { Card, Empty } from "antd";
import "./styles.scss";

const NoData = () => {
  return (
    <Card>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Card>
  );
};
export default NoData;
