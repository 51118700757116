import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Select,
  Space,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import PageHeaders from "../../components/pageHeaders";
import HorizontalLineLoader from "../components/loader";
import TransactionDataTable from "./dataTable";
import DATE_RANGE_OPTIONS from "../../utils/dateRange.json";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../components/loadingBox";

function TransactionScreen() {
  const [form] = useForm();
  const User = useSelector((state: any) => state?.User?.user);
  const id = User?.office?.id;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState({ itemCount: 0 });
  const [totalDueAmount, setTotalDueAmount] = useState<number>(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState<number>(0);
  const today = new Date();
  const navigate = useNavigate();
  today.setHours(0, 0, 0, 0);
  const todayEnd = new Date(today);
  todayEnd.setHours(23, 59, 59, 999);

  const [searchState, setSearchState] = useState({
    query: "",
    filterOption: "ALL",
    sortOrder: "DESC",
    page: 1,
    pageSize: 20,
    startDate: today.toISOString(),
    endDate: todayEnd.toISOString(),
  });

  const [showRangePicker, setShowRangePicker] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState("today");
  const { RangePicker } = DatePicker;

  useEffect(() => {
    fetchTransactions();
    transactionSum();
  }, [
    searchState.page,
    searchState.pageSize,
    searchState.filterOption,
    searchState.query,
    searchState.startDate,
    searchState.endDate,
  ]);

  const getDateRange = (option: string) => {
    let startDate, endDate;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    switch (option) {
      case "all":
        return { startDate: null, endDate: null };
      case "today":
        startDate = endDate = new Date(today);
        break;
      case "yesterday":
        startDate = endDate = new Date(today.getTime() - 86400000);
        break;
      case "this_month":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today);
        break;
      case "last_month":
        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "last_30_days":
        startDate = new Date(today.getTime() - 2592000000);
        endDate = new Date(today);
        break;
      case "last_60_days":
        startDate = new Date(today.getTime() - 5184000000);
        endDate = new Date(today);
        break;
      default:
        return { startDate: null, endDate: null };
    }

    if (endDate) {
      endDate.setHours(23, 59, 59, 999);
    }

    return {
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
    };
  };

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const {
        query,
        sortOrder,
        page,
        pageSize,
        filterOption,
        startDate,
        endDate,
      } = searchState;

      const url = `${API.TRANSACTION_SEARCH}?order=${sortOrder}&page=${page}&take=${pageSize}`;

      const body = {
        endDate: endDate,
        office_id: id.toString(),
        query,
        payment_method: filterOption,
        startDate: startDate,
      };

      const response: any = await POST(url, body);

      if (response?.status) {
        setData(response?.data || []);
        setMeta(response?.meta || { itemCount: 0 });
      } else {
        message.error("Oops! Something went wrong.");
      }
    } catch (error) {
      message.error("Error fetching transactions.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number, newPageSize?: number) => {
    setSearchState((prev) => ({
      ...prev,
      page: newPage,
      pageSize: newPageSize || prev.pageSize,
    }));
  };

  const clearFilters = () => {
    setSearchState({
      query: "",
      filterOption: "ALL",
      sortOrder: "DESC",
      page: 1,
      pageSize: 20,
      startDate: today.toISOString(),
      endDate: todayEnd.toISOString(),
    });
    form.resetFields();
    setSelectedDateRange("today");
    setShowRangePicker(false);
  };

  const dateRangeMenu = (
    <Menu
      items={DATE_RANGE_OPTIONS.map((range) => ({
        key: range.id.toString(),
        label: range.text,
        onClick: () => handleMenuClick(range.value),
      }))}
    />
  );

  const handleDateRangeChange = (dates: any) => {
    if (dates) {
      setSearchState((prev) => ({
        ...prev,
        startDate: dates[0].toISOString(),
        endDate: dates[1].toISOString(),
        page: 1,
      }));
      setSelectedDateRange("custom");
      setShowRangePicker(true);
    } else {
      setSearchState((prev) => ({
        ...prev,
        startDate: today.toISOString(),
        endDate: todayEnd.toISOString(),
        page: 1,
      }));
      setShowRangePicker(false);
      setSelectedDateRange("today");
    }
  };

  const handleMenuClick = (rangeValue: string) => {
    const { startDate, endDate } = getDateRange(rangeValue);

    setSearchState((prev: any) => ({
      ...prev,
      startDate,
      endDate,
      page: 1,
    }));

    setSelectedDateRange(rangeValue);
    setShowRangePicker(rangeValue === "");
  };

  const handleFilterChange = (value: string) => {
    setSearchState((prev) => ({
      ...prev,
      filterOption: value || "ALL",
      page: 1,
    }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchState((prev) => ({
      ...prev,
      query: e.target.value,
      page: 1,
    }));
  };

  const transactionSum = async () => {
    try {
      const { query, filterOption, startDate, endDate } = searchState;

      const req = {
        office_id: id,
        query,
        payment_method: filterOption === "all" ? null : filterOption,
        startDate: startDate || null,
        endDate: endDate || null,
      };

      const response: any = await POST(API.TRANSACTION_TOTAL_2, req);

      if (response?.status) {
        setTotalDueAmount(Number(response?.data?.due || 0));
        setTotalPaidAmount(Number(response?.data?.paid || 0));
      } else {
        message.error("Failed to fetch transaction totals.");
      }
    } catch (err) {
      console.error("Error calculating transaction sums:", err);
      message.error("Error calculating transaction sums.");
    }
  };

  return (
    <div>
      <PageHeaders title="Transaction" breadcrumb="Transaction">
        <Form form={form} layout="inline">
          <div className="d-flex flex-column flex-wrap flex-sm-row gap-2">
            <Form.Item name="query" noStyle>
              <Input
                style={{ width: 200 }}
                placeholder="Search name or phone or email etc ..."
                allowClear
                value={searchState.query}
                onChange={handleSearchChange}
                suffix={<CiSearch size={16} color="#000" />}
              />
            </Form.Item>
            {!showRangePicker && (
              <Form.Item>
                <Dropdown overlay={dateRangeMenu} trigger={["click"]}>
                  <Button>
                    <Space>
                      {DATE_RANGE_OPTIONS.find(
                        (r) => r.value === selectedDateRange
                      )?.text || "Today"}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Form.Item>
            )}
            {showRangePicker && (
              <Form.Item name="date" noStyle>
                <RangePicker
                  onChange={(dates) => handleDateRangeChange(dates)}
                />
              </Form.Item>
            )}
            <Form.Item name="filterOption" noStyle>
              <Select
                showSearch
                placeholder="Select Payment Method"
                optionFilterProp="children"
                onChange={handleFilterChange}
                defaultValue="ALL"
                style={{ width: 100 }}
                value={searchState.filterOption}
                allowClear
              >
                {["ALL", "BANK", "UPI", "CARD", "CASH"].map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button onClick={clearFilters}>Clear Filters</Button>
          </div>
        </Form>
      </PageHeaders>

      {loading ? <HorizontalLineLoader /> : null}

      <div className="screenBox">
        <div className="d-flex gap-3 m-2">
          <Button
            className="text-warning"
            onClick={() => navigate(`/Auth/dueList/${id}`)}
          >
            Total Due Amount : {totalDueAmount < 0 ? 0 : totalDueAmount}
          </Button>
          <Button className="text-success">
            Total Paid Amount : {Number(totalPaidAmount ? totalPaidAmount : 0)}
          </Button>
        </div>

        <Card>
          {loading ? (
            <LoadingBox />
          ) : (
            <TransactionDataTable
              data={data}
              page={searchState.page}
              pageSize={searchState.pageSize}
              total={meta?.itemCount}
              onPageChange={handlePageChange}
            />
          )}
        </Card>
      </div>
    </div>
  );
}

export default TransactionScreen;
