import { Breadcrumb } from "antd";
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
function PageHeader(props: any) {
  const navigation = useNavigate();
  return (
  
    <div className="dashboard-pageHeader">
      <div className="dashboard-pageHeader-back" onClick={() => navigation(props?.goBack ?? -1)}>
        <IoChevronBackOutline size={30} color="grey" />
      </div>
      <div style={{ flex: 1 }}>
        <div className="dashboard-pageHeader-title">{props?.title}</div>
        <Breadcrumb
          style={{ fontSize: 13 }}
          items={[
            {
              href: "/Auth",
              title: "Home",
            },
            {
              title: props.breadcrumb,
            },
          ]}
        />
      </div>
      <div className="dashboard-pageHeader-children">{props?.children}</div>
    </div>
  );
}

export default PageHeader;
