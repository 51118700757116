import { Pagination, Popconfirm, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit, CiRead } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

function SubscriptionDataTable(props: any) {
  const navigate = useNavigate();
  const columns = [
    {
      title: "S No",
      dataIndex: "",
      key: "",
      render: (text: any, record: any, index: any) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Duration Type",
      dataIndex: "duration_type",
      key: "duration_type",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Members",
      dataIndex: "userCount",
      key: "userCount",
      render: (item: any) => <Tag color="green">{item}</Tag>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: any) => moment(date).format("DD-MM-YYYY"),
    },

    {
      title: "Action",
      render: (item: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="View">
            <CiRead
              size={20}
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => navigate(`/Auth/subscriptionDetails/${item?.id}`)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <CiEdit
              size={20}
              style={{ cursor: "pointer", color: "orange" }}
              onClick={() => navigate(`/Auth/createSubscription/${item?.id}`)}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              title="Delete the Subscription"
              onConfirm={() => props?.delete(item?.id)}
              okText="Yes"
              cancelText="No"
            >
              <AiOutlineDelete
                size={20}
                style={{ cursor: "pointer", color: "red" }}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        size="small"
        dataSource={props?.data}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          total={props?.data?.length}
          current={props.current}
          pageSize={props.pageSize}
          showSizeChanger
          showTotal={(total) => `Total ${total} Subscriptions`}
          onChange={(page, pageSize) => props?.onPageChange(page, pageSize)}
          responsive
        />
      </div>
    </>
  );
}

export default SubscriptionDataTable;
