import { useNavigate } from "react-router-dom";

const Cards = ({ icon, label, value, navigate: route }: any) => {
  const navigate = useNavigate();

  return (
    <div className="Info-cards" onClick={() => navigate(route)}>
      <div className="Info-cardBox1">{icon}</div>
      <div className="Info-cardBox2">
        <div className="Info-cardtxt1">{label}</div>
        <div className="Info-cardtxt2">{value}</div>
      </div>
    </div>
  );
};

export default Cards;
