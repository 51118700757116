import {
  CalendarOutlined,
  DollarOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, message, Statistic } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { API } from "../../../../config/Api";
import { DELETE, POST } from "../../../../utils/apiRequest";
import { Col, Row } from "react-bootstrap";
import TransactionSettle from "./transactionSettle";
import CustomModal from "../../../components/customModal/customModal";

const Transactions = (props:any) => {
  const [transactions, setTransactions] = useState([]);
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState<any>(null);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);

  useEffect(() => {
    fetchTransactions();
  }, [props?.id]);

  const fetchTransactions = async () => {
    try {
      const requestPayload = {
        office_id: null,
        user_id: props?.id,
        subscription_id: null,
        payment_method: null,
        period: null,
        value: null,
      };
      const response:any = await POST(
        `${API.TRANSACTIONS}?order=ASC&page=1&take=100`,
        requestPayload
      );

      if (response?.status) {
        setTransactions(response?.data?.entities || []);
        setTotalDueAmount(response?.data?.due || 0);
        setTotalPaidAmount(response?.data?.paid || 0);
      } else {
        message.error("Failed to fetch transactions");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while fetching transactions.");
    }
  };

  const handleDeleteTransaction = async () => {
    if (!selectedTransactionId) return;

    try {
      const deleteUrl = `${API.TRANSACTION_DELETE}${selectedTransactionId}`;
      const response:any = await DELETE(deleteUrl);

      if (response?.status) {
        message.success(response?.message || "Transaction deleted successfully");
        fetchTransactions();
      } else {
        message.error(response?.message || "Failed to delete transaction");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while deleting the transaction.");
    } finally {
      closeModal();
    }
  };

  const openModal = (id:any) => {
    setSelectedTransactionId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTransactionId(null);
  };

  const isPaid = totalPaidAmount >= totalDueAmount;

  return (
    <div>
      <div className="col-12 col-lg-6">
        <Card
          title="Payment Summary"
          style={{
            backgroundColor: isPaid ? "#f6ffed" : "#fff2f0",
          }}
        >
          <Row>
            <Col md={6}>
              <Statistic
                title="Due Amount"
                value={totalDueAmount}
                prefix="$"
                valueStyle={{ color: "red" }}
              />
            </Col>
            <Col md={6} className="text-start text-sm-end">
              <Statistic
                title="Paid Amount"
                value={totalPaidAmount}
                prefix="$"
                valueStyle={{ color: isPaid ? "green" : "inherit" }}
              />
            </Col>
            <Col className="text-end mt-2">
              {totalDueAmount > 0 && (
                <Button
                  className="text-danger"
                  onClick={() => setActiveModal("settleDue")}
                >
                  Settle Due
                </Button>
              )}
            </Col>
          </Row>
        </Card>
      </div>

      <br />

      {transactions.map((item:any, index) => (
        <Card key={index} className="col-12 col-lg-6 mb-3">
          <Row>
            <Col md={6}>
              <div>
                <UserOutlined className="me-2" />
                <strong>{item?.user_name}</strong>
              </div>
              <br />
              <div>
                <CalendarOutlined className="me-2" />
                <strong>
                  {moment(item?.createdAt).format("DD-MM-YYYY h:mm A")}
                </strong>
              </div>
            </Col>
            <Col md={6} className="text-start text-sm-end">
              <div>
                <SolutionOutlined className="me-2" /> {item?.by || ""}
              </div>
              <br />
              <div style={{ fontWeight: "bold" }}>
                Amount: {item?.amount?.toLocaleString() || "0"}
              </div>
            </Col>
          </Row>
          <hr />
          <div className="d-flex justify-content-between">
            <div>
              <DollarOutlined className="me-2" />
              <span>Paid Amount</span>
            </div>
            <div>
              <strong>₹{item?.paid_mount?.toLocaleString() || 0} /-</strong>
            </div>
          </div>
          <div className="text-end mt-3">
            <Button type="primary" danger onClick={() => openModal(item?.id)}>
              Delete
            </Button>
          </div>
        </Card>
      ))}

      {activeModal === "settleDue" && (
        <TransactionSettle
          isModalOpen={Boolean(activeModal)}
          handleCancel={() => setActiveModal(null)}
          data={props?.data}
          due={totalDueAmount}
          reload={fetchTransactions}
        />
      )}

      {isModalOpen && (
        <CustomModal
          isModalOpen={isModalOpen}
          handleCancel={closeModal}
          title="Delete Transaction"
          type="Delete"
          onDelete={handleDeleteTransaction}
        />
      )}
    </div>
  );
};

export default Transactions;
