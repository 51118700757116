import { Calendar, Card, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { API } from "../../../config/Api";
import { POST } from "../../../utils/apiRequest";
import "../styles.scss";

const Attendance = (props: any) => {
  const [attendance, setAttendance] = useState<any[]>();
  const [markedDates, setMarkedDates] = useState<any>({});
  const [logs, setLogs] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = useState<number>(moment().year());

  useEffect(() => {
    if (props?.data?.user?.admisson_id) {
      getAttendance(selectedMonth, selectedYear);
    }
  }, [props?.data, selectedMonth, selectedYear]);

  const getAttendance = async (month: number, year: number) => {
    try {
      setLoading(true);
      const URL = `${API.GET_ATTENDANCE}`;
      const body = {
        id: props?.data?.user?.admisson_id,
        month,
        year,
      };
      const respo: any = await POST(URL, body);

      if (respo?.status) {
        const data = respo?.data || [];
        setAttendance(data);
        markAttendanceDates(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const markAttendanceDates: any = (data: any) => {
    const dates: any = {};
    data?.forEach((item: any) => {
      const date = item?.LogDate?.split("T")[0];
      dates[date] = true;
    });
    setMarkedDates(dates);
  };

  const onSelectDate: any = (date: moment.Moment) => {
    const selectedDateStr = date?.format("YYYY-MM-DD");
    const filteredLogs: any = attendance?.filter(
      (item: any) => item?.LogDate?.split("T")[0] === selectedDateStr
    );
    setLogs(filteredLogs);
  };

  const dateFullCellRender: any = (date: moment.Moment) => {
    const formattedDate = date?.format("YYYY-MM-DD");
    if (markedDates[formattedDate]) {
      return (
        <div className="attentance-box1">
          <div
            className="attentance-box2"
            style={{
              backgroundColor: "#2eb051",
              width: "30px",
              fontWeight: "bold",
              color: "#fff",
              height: "30px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              borderRadius: "50%",
            }}
          >
            {date?.date()}
          </div>
        </div>
      );
    }

    return (
      <div style={{ padding: "10px", textAlign: "center" }}>{date.date()}</div>
    );
  };

  const onPanelChange: any = (value: moment.Moment) => {
    const month = value.month() + 1;
    const year = value.year();
    setSelectedMonth(month);
    setSelectedYear(year);
  };

  if (!props?.data?.user?.admisson_id) {
    return <Spin spinning={true}>Loading attendance data...</Spin>;
  }

  return (
    <Card>
      <Spin spinning={loading}>
        <Calendar
          dateFullCellRender={dateFullCellRender}
          onSelect={onSelectDate}
          onPanelChange={onPanelChange}
          fullscreen={false}
        />
      </Spin>

      <h5 className="user-heading mb-2">Logs</h5>

      {logs?.length ? (
        <div>
          <div className="d-flex gap-4 mb-3">
            <div>{moment(logs[0]?.LogDate).format("ddd - DD-MM-YYYY")}</div>
            <div>
              {(() => {
                if (logs?.length > 1) {
                  const firstLog = moment(logs[0]?.LogDate);
                  const lastLog = moment(logs[logs.length - 1]?.LogDate);
                  const duration = moment.duration(lastLog.diff(firstLog));
                  const hours = duration.hours();
                  const minutes = duration.minutes();
                  return `Total Hours ${hours}:${minutes.toString().padStart(2, "0")} hrs`;
                }
                return "";
              })()}
            </div>
          </div>
          {logs.map((log: any, index: number) => {
            const logTime = moment(log?.LogDate).format("hh:mm A");
            return (
              <div key={index}>
                <p>
                  <strong>{index + 1} - </strong> {logTime}
                </p>
              </div>
            );
          })}
        </div>
      ) : null}
    </Card>
  );
};

export default Attendance;
