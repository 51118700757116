import { API } from "../config/Api";

const GET = async (url: any, params: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const POST = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const PUT = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const DELETE = async (url: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.BASE_URL + url, {
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const COMPRESS_IMAGE = async (file: File) => {
  try {
    if (!file) return Promise.reject(new Error("No Image Is selected.."));
    const formData = new FormData();
    formData.append("file", file);
    const response = await fetch(`${API.BASE_URL}${API.IMAGE_COMPRESS}`, {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    if (!response?.ok)
      return Promise.reject(
        new Error(data?.message ?? "Something went wrong..")
      );
    return { ...data, url: data.Location, status: true };
  } catch (err: any) {
    return Promise.reject(new Error(err.message));
  }
};



export {GET, POST, PUT, DELETE,COMPRESS_IMAGE};