import { Tabs } from "antd";
import { useEffect, useState } from "react";
import PageHeaders from "../../components/pageHeaders";
import ProfilePassword from "./changePassword";
import EditProfile from "./edit";
import { useSelector } from "react-redux";
import BroadCast from "./broadCast";
import TermsCondition from "./termsCondition";
import ContactUs from "./contact";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";

const ProfileScreen = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const User = useSelector((state: any) => state?.User?.user);

  const getUser = async () => {
    try {
      setIsLoading(true);
      let api = API.USER_DETAILS + User?.user?.id;
      const response: any = await GET(api, null);
      if (response?.status) {
        setData(response?.data);
        setIsLoading(false);
      } else {
        console.error(response?.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    if (User?.user?.id) {
      getUser();
    }
  }, []);

  const items = [
    {
      key: "profile",
      label: "Edit Profile",
      tab: "profile",
      children: <EditProfile data={data?.user} reload={getUser} />,
    },

    {
      key: "password",
      label: "Change Password",
      tab: "password",
      children: <ProfilePassword data={data} reload={getUser} />,
    },
    {
      key: "policy",
      label: "Policy , Terms & Conditions",
      tab: "policy",
      children: <TermsCondition />,
    },
    {
      key: "contact",
      label: "Contact Us",
      tab: "contact",
      children: <ContactUs />,
    },
    {
      key: "Broadcast",
      label: "Broadcast",
      tab: "Broadcast",
      children: <BroadCast data={data}/>,
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div>
      <PageHeaders title={"Profile"} breadcrumb={"Profile Details"} />
      <Tabs activeKey={activeTab} onChange={onChange} items={items} />
    </div>
  );
};

export default ProfileScreen;
