import { Button, Card, Form, Input, notification } from "antd";
import { useState } from "react";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";

const ProfilePassword = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values: any) => {
    const { oldPassword, newPassword } = values;
    try {
      setIsLoading(true);
      const response: any = await POST(API.UPDATE_PASS, {
        id: props?.data?.user?.id,
        oldPassword,
        newPassword,
      });

      if (response?.status) {
        notification.success({
          message: "Success",
          description: "Password updated successfully.",
        });
        props?.reload();
      } else {
        notification.error({
          message: "Error",
          description: response?.message || "Failed to update password.",
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "An error occurred while updating password.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Card className="col-12 col-lg-5">
        <h5 className="user-heading">Change Password</h5>

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          hideRequiredMark
        >
          <Form.Item
            label="Current Password"
            name="oldPassword"
            rules={[
              { required: true, message: "Current Password is required" },
            ]}
          >
            <Input.Password placeholder="Enter your current password" />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "New Password is required" },
              { min: 8, message: "Password must be at least 8 characters" },
            ]}
          >
            <Input.Password placeholder="Enter your new password" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Confirm Password is required" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm your new password" />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ProfilePassword;
