import { Button, DatePicker, Form, Input, Modal } from "antd";
import moment from "moment";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

const PauseResumeModal = ({
  isModalOpen,
  handleCancel,
  data,
  onSubmit,
  holdResume,
  isLoading
}: any) => {
  const [from, setFrom] = useState<moment.Moment | null>(moment());
  const [to, setTo] = useState<moment.Moment | null>(moment().add(1, "day"));
  const [days, setDays] = useState<number>(1);

  const submit = () => {
    onSubmit(from, to, days);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      centered
      closeIcon={false}
    >
      <h5 className="user-heading">
        {holdResume?.id ? "Resume" : "Pause"} Subscription
      </h5>
      <Form layout="vertical" onFinish={submit} hideRequiredMark>
        {!holdResume ? (
          <>
            <Form.Item label="Hold From" required>
              <DatePicker
                className="w-100"
                value={from}
                onChange={(date) => {
                  setFrom(date);
                  setTo(date ? date.clone().add(1, "day") : null);
                }}
                disabledDate={(current: any) =>
                  current &&
                  moment(current).isAfter(moment(data?.end_date), "day")
                }
              />
            </Form.Item>
            <Form.Item label="Resume On" required>
              <DatePicker
                className="w-100"
                value={to}
                onChange={setTo}
                disabledDate={(current: any) =>
                  from ? moment(current).isSameOrBefore(from, "day") : false
                }
              />
            </Form.Item>
            <Form.Item label="Total Number of Days">
              <Input
                type="number"
                value={days}
                onChange={(e) => {
                  const val = Number(e.target.value);
                  setDays(val);
                  setTo(from?.clone().add(val, "days") || null);
                }}
              />
            </Form.Item>
          </>
        ) : (
          <div className="p-2">
            <p>
              Subscription Expiry:
              {moment(holdResume?.current_exp).format("DD-MM-YYYY")}
            </p>
            <p>
              Hold Period:
              {`${moment(holdResume?.hold_from).format(
                "DD-MM-YYYY"
              )} to ${moment(holdResume?.hold_to).format("DD-MM-YYYY")}`}
            </p>
            <p>
              Extended Expiry:
              {moment(holdResume?.current_exp)
                .add(Number(holdResume?.num_days), "days")
                .format("DD-MM-YYYY")}
            </p>
            <p>
              No. days: {holdResume?.num_days} day
              {holdResume?.num_days > 1 ? "s" : null}
            </p>
          </div>
        )}
        <Row>
          <Col md={5}></Col>
          <Col md={7}>
            <div className="d-flex gap-2">
              <Button block onClick={handleCancel}>
                Close
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isLoading}
              >
                {holdResume?.id ? "Resume Now" : "Pause Now"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PauseResumeModal;
