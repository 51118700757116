import { Button, Form, Input, Modal, Select, InputNumber, message } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../../../config/Api";
import { POST } from "../../../../utils/apiRequest";

const TransactionSettle = ({
  isModalOpen,
  handleCancel,
  data,
  isLoading,
  due,
  reload
}: any) => {
  const onFinish = async (val: any) => {
    try {
      const obj = {
        office_id: data?.office?.id,
        user_id: data?.user?.id,
        subscription_id: data?.user?.subscription_id,
        user_name: `${data?.user?.first_name} ${data?.user?.last_name}`,
        amount: "0",
        paid_mount: val?.paid_mount.toString(),
        payment_method: val?.payment_method,
        comment: val?.comment,
        subscription_name: data?.user?.subscription_name,
        start_date: new Date(),
        end_date: new Date(),
        type: data?.user?.type,
      };
      const response: any = await POST(API.PAY_DUE, obj);
        if (response?.status) {
            message.success(response?.message || "Successfully Settled the amount")
            handleCancel()
            reload()
        } else {
            message.error(response?.message || "Failed to Settle the amount");
        }
        
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      centered
      closeIcon={false}
    >
      <h5 className="user-heading">Settle Due</h5>
      <Form layout="vertical" onFinish={onFinish} hideRequiredMark>
        <Form.Item >
          <Button disabled className="text-danger">
          Total Due Amount : {due}
          </Button>
        </Form.Item>
        <Form.Item
          label="Amount to pay"
          name="paid_mount"
          rules={[
            { required: true, message: "Please enter a valid amount" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value > due) {
                  return Promise.reject(
                    new Error(`Amount cannot exceed ${due}`)
                  );
                }
                if (value < 1) {
                  return Promise.reject(
                    new Error("Amount must be at least 1")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            min={1}
            max={due}
            style={{ width: "100%" }}
            placeholder="Enter amount"
          />
        </Form.Item>
        <Form.Item label="Comments" name="comment">
          <Input placeholder="Settled..." />
        </Form.Item>
        <Form.Item
          name="payment_method"
          label="Payment Method"
          rules={[{ required: true, message: "Choose Payment Method" }]}
        >
          <Select placeholder="Choose Payment Method">
            {["BANK", "UPI", "CARD", "CASH"].map((method, idx) => (
              <Select.Option value={method} key={idx}>
                {method}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Row>
          <Col md={5}></Col>
          <Col md={7}>
            <div className="d-flex gap-2">
              <Button block onClick={handleCancel}>
                Close
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isLoading}
              >
                Settle
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TransactionSettle;
