import { message } from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosTimer } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { PiUsersThreeDuotone } from "react-icons/pi";
import { useSelector } from "react-redux";
import { API } from "../../config/Api";
import { GET } from "../../utils/apiRequest";
import Cards from "./component/cards";
import ExpiringUser from "./component/expirinUser";
import GraphChart from "./component/graph";

import "./styles.scss";

function Dashboard() {
  const User = useSelector((state: any) => state?.User?.user);
  const id = User?.office?.id;
  const [loading, setLoading] = useState(false);

  const [expiringUsers, setExpiringUsers] = useState<[]>();
  const [expiredUsers, setExpiredUsers] = useState<[]>();
  const [birthdays, setBirthdays] = useState<any[]>();
  const [chartData, setChartData] = useState<any>([]);
  const [userCount, setUserCount] = useState<any>([]);



  

  const getExpiringUsers = async () => {
    try {
      let api = `${API.EXPIRE_SOON_USERS + id}?order=DESC&page=1&take=50`;
      let expired: any = await GET(api, {});
      if (expired?.status) {
        setExpiringUsers(expired.data);
        setLoading(false);
      } else {
        setUserCount([]);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getExpiredUsers = async () => {
    try {
      let api = `${API.EXPIRED_USERS}${id}?order=DESC&page=1&take=50`;
      let expired: any = await GET(api, {});
      if (expired?.status) {
        setExpiredUsers(expired.data);
        setLoading(false);
      } else {
        setUserCount([]);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getBirthdayUsers = async () => {
    try {
      let api = `${API.BIRTHDAY_USERS}${id}?order=DESC&page=1&take=50`;
      let expired: any = await GET(api, {});
      if (expired?.status) {
        setBirthdays(expired.data);
        setLoading(false);
      } else {
        setBirthdays([]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getUserCount = async () => {
    try {
      let api = `${API.USER_COUNT}${id}`;
      let count: any = await GET(api, {});
      if (count?.status) {
        setUserCount(count.data);
      } else {
        setUserCount([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChartData = async () => {
    try {
      let api = `${API.SUBCRIPTION_ALL}${id}`;
      const response: any = await GET(api, null);
      if (response?.status) {
        let res = response?.data;
        if (res.length) {
          let arr: any = [];
          response?.data.map((item: any, index: number) => {
            let obj = {
              textColor: "#525252",
              value: Number(item?.userCount),
              label: item?.name,
              labelTextStyle: {
                marginTop: 10,
                transform: [{ rotate: "-45deg" }],
                color: "#525252",
                width: 55,
                height: 20,
              },
            };
            arr = [obj, ...arr];
          });
          setChartData(arr);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.error(response?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const sortedBarData = chartData
    ?.slice()
    ?.sort((a: any, b: any) => b.value - a.value);

  useEffect(() => {
    getBirthdayUsers();
    getExpiredUsers();
    getExpiringUsers();
    getChartData();
    getUserCount()
  }, []);

  console.log(userCount,"userCount");

  const cardItems = [
    {
      icon: <PiUsersThreeDuotone />,
      label: "All Users",
      value: userCount?.allUsers|| 0,
      navigate: "users/all",
    },
    {
      icon: <FaRegCircleCheck />
      ,
      label: "Active",
      value: userCount?.activeUsers
       || 0,
      navigate: "users/active",
    },
    {
      icon: <IoIosTimer />
      ,
      label: "Expire Soon",
      value: userCount?.expireInTenDays|| 0,
      navigate: "users/expiring_10_days",
    },
    {
      icon: <IoCloseCircleOutline />
      ,
      label: "Expired",
      value: userCount?.expiredUsers || 0,
      navigate: "users/inactive",
    },
  ];

  

  return (
    <Container>
      <br />
      <Row>
        {cardItems?.map((card, idx) => (
          <Col md={3} key={idx}>
            <Cards
              icon={card?.icon}
              label={card?.label}
              value={card?.value}
              navigate={card.navigate}
            />
          </Col>
        ))}
      </Row>
      <br />
      <Row>
        <Col md={7}>
          <div className="dashboard-Txt1">GYM GRAPH</div>
          <div className="Dashboard-graph">
            <br />
            <GraphChart data={sortedBarData} />
          </div>
        </Col>
        <Col md={5}>
          {birthdays?.length ? <ExpiringUser data={birthdays} type="Today Birthday" total={birthdays?.length} /> : null}
          {expiringUsers?.length ? <ExpiringUser data={expiringUsers} type="Expired in 5 days" total={expiringUsers?.length} /> : null}
          {expiredUsers?.length ? <ExpiringUser data={expiredUsers} type="Recently Expired" total={expiredUsers?.length} /> : null}

        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
