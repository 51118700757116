import moment from 'moment';

const FindDateRange = async (type: any, duration: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      var start: any = new Date();
      var end: any = new Date();
      if (type === 'Hours') {
        start = moment(new Date());
        end = moment(start).add(duration, 'hour');
      }
      if (type === 'Day') {
        start = moment(new Date());
        end = moment(start).add(duration, 'day');
      }
      if (type === 'Week') {
        start = moment(new Date());
        end = moment(start).add(duration, 'week');
      }
      if (type === 'Month') {
        start = moment(new Date());
        end = moment(start).add(duration, 'month');
      }
      if (type === 'Year') {
        start = moment(new Date());
        end = moment(start).add(duration, 'year');
      }
      let obj = {
        start: start,
        end: end,
      };
      resolve(obj);
    } catch (error) {
      reject(null);
      console.log('error', error);
    }
  });
};
export {FindDateRange};
