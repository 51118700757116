import { Button, Card } from "antd";
import moment from "moment";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CiCalendarDate } from "react-icons/ci";
import { FaBell } from "react-icons/fa";
import { IoCardOutline, IoFingerPrintSharp } from "react-icons/io5";
import { API } from "../../../config/Api";
import { POST } from "../../../utils/apiRequest";
import CustomModal from "../../components/customModal/customModal";
import FingerPrint from "./fingerPrintModal";
import PauseScreen from "./pauseScreen";
import RenewSubscriptionModal from "./renewSubscriptionModal";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import WhatsAppModal from "../../../components/whatsappSender";
import { TbFaceId } from "react-icons/tb";
import { checkNullValue } from "../../../utils/variable";

const Info = (props: any) => {
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [message, setMessage] = useState<string>("");

  const showModal = (modal: string) => {
    setActiveModal(modal);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const handleRemoveSubscription = async () => {
    try {
      let url = API.REMOVE_SUB;
      let payload = props?.data?.user;
      let response: any = await POST(url, payload);
      if (response?.status) {
        props?.reload();
        closeModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkingExpireIn5 = (data: any) => {
    let start = moment().format("YYYY-MM-DD");
    let end = moment(start).add(5, "days").format("YYYY-MM-DD");
    let sub_date = moment(data).format("YYYY-MM-DD");
    let check = moment(sub_date).isBetween(start, end, "day", "[]");
    let diffrent = moment(start).diff(sub_date, "days");
    let message = `Subscription Expired on ${sub_date}`;
    if (!check) {
      check = moment(sub_date).isBefore(start, "day");
    }
    if (!check) {
      check = moment(sub_date).isSame(start, "day");
    }
    let before = moment(sub_date).isBefore(start);
    if (before) {
      message = `Subscription expired ${Math.abs(diffrent)} days ago.`;
    } else {
      message = `Subscription is expiring in ${Math.abs(diffrent)} days.`;
    }
    let obj = {
      status: check,
      days: diffrent,
      message: message,
    };
    return obj;
  };

  const checkBirthday = () => {
    try {
      if (props?.data?.user?.birth_date) {
        const today = new Date();
        const birthDate = new Date(props?.data?.user?.birth_date);
        return (
          today.getDate() === birthDate.getDate() &&
          today.getMonth() === birthDate.getMonth()
        );
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hasFaceDevice = props?.data?.office?.devices?.some((device: any) =>
    device?.gates?.includes('face'),
  );
  
  const hasFingerDevice = props?.data?.office?.devices?.some((device: any) =>
    device?.gates?.includes('finger'),
  );

  return (
    <div>
      <Card
        title={
          props?.data?.user?.subscription_id
            ? "Subscription"
            : "No Subscription Found"
        }
        className="col-12 col-lg-6"
        style={{
          backgroundColor: props?.data?.user?.subscription_id
            ? "#f6ffed"
            : "#fff2f0",
        }}
      >
        <Row>
          <Col md={6} className="mb-3">
          <div>
            <IoCardOutline className="me-2" size={20} />
            <span> Subscription {props?.data?.subscription?.name}</span>
            </div>
            <div>
              <br/>
            <CiCalendarDate size={20} className="me-2" />
            <span>
              Ending Date :
              {moment(props?.data?.user?.end_date).format("DD/MM/YYYY")}
            </span>
          </div>
          </Col>
          <Col md={6} >
          <div className="text-start text-sm-end">
            {props?.data?.subscription?.price} -
              <span> {props?.data?.subscription?.time} hrs / day</span>
              <div>
              <br />
            <Button
              className={
                props?.data?.user?.subscription_id
                  ? "text-success"
                  : "text-danger"
              }
              onClick={() => showModal("renewsubscription")}
            >
           <IoCardOutline className="me-2" size={20} />    {props?.data?.user?.subscription_id
                ? "Change Subscription Plan"
                : "Add Subscription Plan"}
            </Button>
          </div>
            </div>
          </Col>
        </Row>
      </Card>
      <br />
      <Card >
        <h5 className="user-heading">Notification</h5>
        <div className="d-flex gap-4 flex-wrap">
          {hasFingerDevice &&  <Button
            className="text-success flex items-center"
            onClick={() => showModal("fingerPrint")}
          >
            <IoFingerPrintSharp className="me-2" />
            Add Fingerprint
          </Button>}
          {hasFaceDevice &&  <Button
            className="text-success flex items-center"
            onClick={() => showModal("facePrint")}
          >
            <TbFaceId className="me-2" size={17}/>

            Add Face
          </Button>}
         
          {props?.data?.user?.subscription_id ? (
            <Button
              className="text-danger flex items-center"
              onClick={() => showModal("subscription")}
            >
              <IoCardOutline className="me-2" size={18} />
              Remove Subscription
            </Button>
          ) : (
            <Button className="h-50 text-danger" onClick={() => {
              const message = `Your subscription expired on ${moment(
                props?.data?.user?.end_date,).format('DD MMM YYYY')} in ${props?.data?.office?.name}\nKindly subscribe to any package to continue. \n${props?.data?.office?.name}\n${props?.data?.office?.address}\n${props?.data?.office?.phone}\n\n${checkNullValue(props?.data?.office?.more_info)}`;
                setMessage(message); 
                showModal("whstsapp")
              }} >
                 <FaBell className="me-2" />
             Send Subscription Reminder
              <br />
              {checkingExpireIn5(props?.data?.user?.end_date)?.message}
            </Button>
          )}

          {props?.data?.user?.end_date &&
            props?.data?.user?.subscription_id && (
              <PauseScreen data={props?.data} getUser={props?.reload} />
            )}
          {checkingExpireIn5(props?.data?.user?.end_date)?.status &&
          props?.data?.user?.subscription_id ? (
            <Button
              className="text-danger h-50"  onClick={() => {
                const message = `Your subscription on ${props?.data?.subscription?.name} in ${props?.data?.office?.name} will expire within ${moment(props?.data?.user?.end_date).format('DD MMM YYYY')}.
                Kindly renew your subscription before expiration.`;
                setMessage(message); 
                showModal("whstsapp")}}
            >
              <FaBell className="me-2" />
              Subscription Renewal Reminder
              <div className="text-black">
                {checkingExpireIn5(props?.data?.user?.end_date)?.message}
              </div>
            </Button>
          ) : null}

          {checkBirthday() ? (
             <Button className="h-50 text-success"   onClick={() => {
                  const birthdayMessage = `Happy Birthday, ${props?.data?.user?.first_name}! 🎉🎂 May your day be filled with joy, laughter, and special moments. Wishing you a fantastic year ahead! 🎈🥳.\n\n ${props?.data?.office?.name}`;
                  setMessage(birthdayMessage); 
                  showModal("whstsapp")}}>
                  <LiaBirthdayCakeSolid className="me-2" size={20}/>
                  It's {props?.data?.user?.first_name}'s Birthday today <br/>
                  <span>Wish {props?.data?.user?.first_name}</span> 
             </Button>
          ) : null}
        </div>
      </Card>
      <br />
      <Card >
      <h5 className="user-heading">Personal Details</h5>
        <Row>
          <Col md={6}>
            <div>
              <div> Email : {props?.data?.user?.email}</div>
              <div> Primary Mobile : {props?.data?.user?.mobile_number}</div>
              <div> Secondary Mobile : {props?.data?.user?.mobile_number2}</div>
              <div>
                Date of Birth :
                {moment(props?.data?.user?.birth_date).format("MMMM Do, YYYY")}
              </div>
              <div> Blood Group : {props?.data?.user?.blood}</div>
            </div>
          </Col>
          <Col md={6}>
            <div>
              <div> Gender : {props?.data?.user?.gender}</div>
              <div> Address : {props?.data?.user?.place}</div>
              <div> Height : {props?.data?.user?.height}</div>
              <div> Weight : {props?.data?.user?.weight}</div>
            </div>
          </Col>
        </Row>
      </Card>
      {activeModal === "fingerPrint" || activeModal === "facePrint" ? (
      <FingerPrint
        data={props?.data?.user}
        isModalOpen={activeModal === "fingerPrint" || activeModal === "facePrint"}
        handleCancel={closeModal}
        reload={props?.reload}
        enroll={activeModal === "fingerPrint"}/>
        ) : null}

      {activeModal === "renewsubscription" && (
        <RenewSubscriptionModal
          data={props?.data?.user}
          isModalOpen={activeModal === "renewsubscription"}
          handleCancel={closeModal}
          reload={props?.reload}
        />
      )}

      {activeModal === "subscription" && (
        <CustomModal
          data={props?.data?.user}
          isModalOpen={activeModal === "subscription"}
          handleCancel={closeModal}
          title="Subscription"
          type="Remove"
          onDelete={handleRemoveSubscription}
        />
      )}
      {activeModal === "whstsapp" && (
        <WhatsAppModal
        phone={props?.data?.user?.mobile_number || props?.data?.user?.mobile_number2}
        message={message}
          isModalOpen={activeModal === "whstsapp"}
          handleCancel={closeModal}
          name={props?.data?.user?.first_name}
       
        />
      )}
    </div>
  );
};

export default Info;
