import { useParams } from "react-router-dom";
import PageHeaders from "../../../../components/pageHeaders";
import { API } from "../../../../config/Api";
import { POST } from "../../../../utils/apiRequest";
import { useEffect, useState } from "react";
import { Card, Form, Input } from "antd";
import { CiSearch } from "react-icons/ci";
import DueDataTable from "./dataTable";
import LoadingBox from "../../../../components/loadingBox";

const TransactionDueList = () => {
  const { id } = useParams<{ id: string }>(); // Typed useParams
  const [isLoading, setIsLoading] = useState(false); // Fixed typo: `isLaoding` -> `isLoading`
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    page: 1,
    take: 20,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [searchState, setSearchState] = useState({
    query: "",
    page: 1,
    pageSize: 20,
  });

  useEffect(() => {
    fetchDues();
  }, [searchState]);

  const fetchDues = async () => {
    try {
      setIsLoading(true);

      const api = `${API.DUES_LIST}?order=DESC&page=${searchState.page}&take=${searchState.pageSize}`;
      const requestPayload = {
        office_id: id,
        search: searchState.query,
      };

      const res: any = await POST(api, requestPayload);

      if (res?.status) {
        setData(res.data || []);
        setMeta(res.meta || {});
      }
    } catch (err) {
      console.error("Error fetching dues:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setSearchState((prev) => ({
      ...prev,
      query: newQuery,
      page: 1, // Reset to the first page for a new search
    }));
  };

  const handlePageChange = (newPage: number, newPageSize?: number) => {
    setSearchState((prev) => ({
      ...prev,
      page: newPage,
      pageSize: newPageSize || prev.pageSize,
    }));
  };

  return (
    <div>
      <PageHeaders title="Due Transaction" breadcrumb="Due Transaction List">
        <Form>
          <Form.Item name="query" noStyle>
            <Input
              style={{ width: 300 }}
              placeholder="Search by name, phone, or email..."
              allowClear
              value={searchState.query}
              onChange={handleSearchChange}
              suffix={<CiSearch size={16} color="#000" />}
            />
          </Form.Item>
        </Form>
      </PageHeaders>
      <Card>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <DueDataTable
            data={data}
            page={searchState.page}
            pageSize={searchState.pageSize}
            total={meta.itemCount}
            onPageChange={handlePageChange}
          />
        )}
      </Card>
    </div>
  );
};

export default TransactionDueList;
