import { Button, Card, Form, Input, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import { checkNullValue } from "../../utils/variable";
import { useForm } from "antd/es/form/Form";
import PageHeaders from "../../components/pageHeaders";

const OfficeScreen = () => {
  const { TextArea } = Input;
  const user = useSelector((state: any) => state?.User?.user);
  const details = user?.office;
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const onFinish = async (values: { more_info: string }) => {
    try {
      setLoading(true);
      const requestPayload = {
        office_id: details?.id,
        info: checkNullValue(values?.more_info),
      };
      const response: any = await POST(API.OFFICE_INFO_UPDATE, requestPayload);

      if (response?.status) {
        message.success("Office information updated successfully.");
        getOfficeInfo();
      } else {
        message.success("Failed to update office information.");
      }
    } catch (error) {
      console.error("Error updating office information:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOfficeInfo();
  }, []);

  const getOfficeInfo = async () => {
    try {
      let url = `${API.GET_OFFICE_INFO + details?.id}`;
      let response: any = await GET(url, null);
      if (response?.status) {
        form.setFieldsValue({
          more_info: response?.data?.office?.more_info,
        });
      } else {
        message.error("oops.something gone wrong.");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeaders title={"Office"} breadcrumb={"Office Info"} />
      <div className="screenBox">
        <Card className="col-12 col-lg-5 mb-2">
          <h5 className="user-heading">{details?.name || "Office Details"}</h5>

          <Row>
            <Col md={5}>
              <div>
                <img
                  src={details?.logo}
                  alt={details?.logo}
                  className="rounded-circle me-3 mb-2"
                  style={{ width: "70px", height: "70px" ,border: "1px solid grey"}}
                />
                <div>{details?.name}</div>
                <div>{details?.address}</div>
              </div>
            </Col>

            <Col md={7}>
              <div>
                <div>
                  Office Time :{moment(details?.opening_time).format("h:mm A")}{" "}
                  -{moment(details?.closing_time).format("h:mm A")}
                </div>
                <div>Office Email : {details?.email}</div>
                <div>Office Phone : {details?.phone}</div>
              </div>
            </Col>
          </Row>
        </Card>
        <Card className="col-12 col-lg-5">
          <h5 className="user-heading">More Info</h5>
          <div>
            <p>
              This information will be shown in all notifications and messages.
            </p>
            <Form onFinish={onFinish} form={form}>
              <Form.Item
                name="more_info"
                rules={[
                  {
                    required: true,
                    message: "Please provide additional information.",
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="Enter additional information about the office"
                />
              </Form.Item>
              <div className="text-end">
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Update
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default OfficeScreen;
