import { Button, Form, message, Modal, Select } from "antd";
import finger from "../../../utils/fingers.json";
import { useState } from "react";
import { POST } from "../../../utils/apiRequest";
import { API } from "../../../config/Api";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

const FingerPrint = (props: any) => {
  const User = useSelector((state: any) => state?.User?.user);
  const [isLoading, setLoading] = useState(false);

  const [selectedGateway, setSelectedGateway] = useState<any[]>(
    User?.office?.devices?.filter((device: any) =>
      device?.gates?.includes(props?.enroll ? "finger" : "face")
    ) || []
  );

  const onFinish = async (val: any) => {
    try {
     setLoading(true);
      const url = props?.enroll ? API.ENROLL_FINGER : API.ENROLL_FACE;
      const payload = {
        admisson_id: props?.data?.id,
        SerialNumber: selectedGateway[0]?.device_id,
        ...(props?.enroll && { FingerIndexNumber: val?.FingerIndexNumber }), 
      };

      const response = await POST(url, payload);
      
      if (response) {
        message.success(`Successfully enrolled ${props?.enroll ? "Finger" : "Face"}`);
        props?.reload();
        props.handleCancel();
      } else {
        message.error("Failed to enroll biometric data");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while enrolling biometric data");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={props.isModalOpen}
        onCancel={props.handleCancel}
        footer={null}
        closable={false}
        centered
      >
        <h5 className="user-heading">
          Biometric {props?.enroll ? "Fingerprint" : "Face"} ({props?.data?.admisson_id})
        </h5>
        
        <Form layout="vertical" onFinish={onFinish} hideRequiredMark>
          <Form.Item
            name="gate"
            label="Gate"
            // rules={[{ required: true, message: "Please choose a gate" }]}
          >
            <Select
              placeholder="Choose Gate"
              defaultValue={selectedGateway?.[0]?.device_id} 
            >
              {selectedGateway?.map((item: any, idx: number) => (
                <Select.Option value={item?.device_id} key={idx}>
                  {item?.device_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {props?.enroll && (
            <Form.Item
              name="FingerIndexNumber"
              label="Choose Finger"
              rules={[{ required: true, message: "Please choose a finger" }]}
              initialValue={5}
            >
              <Select placeholder="Choose Finger">
                {finger?.map((item: any, idx) => (
                  <Select.Option value={item?.val} key={idx}>
                    {item?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Row>
            <Col md={5}></Col>
            <Col md={7}>
              <div className="d-flex gap-2">
                <Button block onClick={props?.handleCancel}>
                  Close
                </Button>
                <Button block type="primary" htmlType="submit" loading={isLoading}>
                  Enroll {props?.enroll ? "Finger" : "Face"}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default FingerPrint;
