import { Button, Form, message, Tabs } from "antd";
import { useEffect, useState } from "react";
import { MdBlock } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { GET, POST } from "../../utils/apiRequest";
import CustomModal from "../components/customModal/customModal";
import Attendance from "./components/attentance";
import Info from "./components/info";
import Transactions from "./components/transaction/transaction";
import "./styles.scss";
import { useSelector } from "react-redux";

const UserDetails = () => {
  const { id } = useParams<{ id: string }>();
  const User = useSelector((state: any) => state?.User?.user);

  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Info");

  const showModal = () => {
    setIsBlock(true);
  };

  const closeModal = () => {
    setIsBlock(false);
  };
  const getUser = async () => {
    try {
      const response: any = await GET(API.USER_DETAILS + id, null);
      if (response?.status) {
        setData(response?.data || []);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    getUser();
  }, [id]);

  const blockUnbloack = async () => {
    try {
      let obj = {
        id: id,
        type: data?.user?.status ? false : true,
      };
      const response: any = await POST(API.USER_BLOCK, obj);
      if (response?.status) {
        message.success(response?.message);
        closeModal();
      } else {
        message.error(response?.error || "Cant able to block or unblock");
      }
      getUser();
    } catch (err) {
      console.log(err);
    }
  };

  const items = [
    {
      key: "Info",
      label: "Info",
      tab: "Info",
      children: <Info data={data} id={id} reload={getUser} />,
    },
    ...(User?.user?.type !== "trainer"
      ? [
          {
            key: "Transactions",
            label: "Transactions",
            tab: "Transactions",
            children: <Transactions data={data} id={id} />,
          },
        ]
      : []),
    {
      key: "Attendance",
      label: "Attendance",
      tab: "Attendance",
      children: <Attendance data={data} />,
    },
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <div>
      <PageHeaders title={"User Details"} breadcrumb={"User Details"}>
        <Form layout="inline">
          <Form.Item>
            <Button onClick={() => navigate(`/Auth/createUser/${id}`)}>
              Edit
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={() => showModal()} className="text-danger">
              <MdBlock size={16} className="me-1" />
              {data?.user?.status ? "Block" : "Un Block"}
            </Button>
          </Form.Item>
        </Form>
      </PageHeaders>
      <Tabs activeKey={activeTab} onChange={onChange} items={items} />
      {isBlock && (
        <CustomModal
          isModalOpen={isBlock}
          handleCancel={closeModal}
          title="User"
          type="Block"
          onDelete={blockUnbloack}
        />
      )}
    </div>
  );
};

export default UserDetails;
