import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  notification,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { COMPRESS_IMAGE, GET, POST } from "../../utils/apiRequest";
import CountryCode from "../../utils/countryCodes.json";
import { FindDateRange } from "../../utils/dateRange";
import ImagePicker from "../components/imagePicker";
import "./styles.scss";

function CreateBooking() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState<any>([]);
  const [selectedSub, setSelectedSub] = useState<any>(null);
  const [start_date, setStart_date] = useState<any>(null);
  const [end_date, setEnd_date] = useState<any>(null);
  const [price_amount, setPriceAmount] = useState<number>(0);
  const [duration, setDuration] = useState<any>(null);
  const [durationType, setDurationType] = useState<any>(null);
  const [accountType, setAccountType] = useState<string | null>(null);
  const User = useSelector((state: any) => state?.User?.user);
  const [userId, setUserId] = useState<any>();
  const [image1, setImage1] = useState<any>();
  const [image2, setImage2] = useState<any>();
  const [subscriptionData, setSubscriptionData] = useState<any>();

  useEffect(() => {
      getDetails();
  }, []);

  const [form] = Form.useForm();
  const params = useParams();

  const getDetails = async () => {
    try {
      const details: any = await GET(
        `${API.SUBCRIPTION_OFFICE}${User?.office?.id}`,
        {}
      );
      if (details?.status) {
        setSubscription(details?.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAccountTypeChange = (value: string) => {
    setAccountType(value);
  };

  const handleSubscriptionChange = async (value: any, option: any) => {
    try {
      setSelectedSub(option);
      const { duration_type, duration, price } = option;
      setDuration(duration);
      setDurationType(duration_type);
      const ranges: any = await FindDateRange(duration_type, duration);
      setStart_date(ranges.start);
      setEnd_date(ranges.end);
      setPriceAmount(price);
    } catch (err) {
      console.error("Error selecting subscription:", err);
    }
  };

  const ChangeSubDate = (start: any, end: any, type: any) => {
    try {
      if (!start || !end) {
        setStart_date(null);
        setEnd_date(null);
        return;
      }
      setStart_date(start);
      setEnd_date(end);
      const duro = duration || 0;
      if (type === 1) {
        let endDate = moment(start).add(duro, durationType);
        setEnd_date(endDate);
        form.setFieldsValue({ endDate: endDate });
      } else {
        let startDate = moment(end).subtract(duro, durationType);
        setStart_date(startDate);
        form.setFieldsValue({ startDate: startDate });
      }
    } catch (err) {
      console.log("Error in ChangeSubDate:", err);
    }
  };

  const onFinish = async (val: any) => {
    try {
      setIsLoading(true);
      const upload = image1 && (await COMPRESS_IMAGE(image1?.file));
      const upload2 = image2 && (await COMPRESS_IMAGE(image2?.file));
      const selectedDevices = val?.gate?.map((deviceId: string) => 
        User?.office?.devices?.find((device: any) => device?.device_id === deviceId)
      );
      const payload = {
        first_name: val?.first_name ||"",
        last_name: val?.last_name ||"",
        mobile_number:val?.mobile_number ?  val?.prefix1 + val?.mobile_number : "",
        mobile_number2:val?.mobile_number2 ? val?.prefix2 + val?.mobile_number2 : "",
        birth_date: val?.birth_date ,
        place: val?.place,
        blood: val?.blood,
        gender: val?.gender,
        weight: val?.weight,
        height: val?.height,
        subscription_name: selectedSub?.name || subscriptionData?.name,
        subscription_id: selectedSub?.id || subscriptionData?.id,
        end_date: end_date || moment(userId?.end_date),
        start_date: start_date || moment(userId?.start_date),
        photo: upload?.url,
        id_proof: upload2?.url,
        email: val?.email,
        gates: selectedDevices,
        type: val?.type,
        password: val?.password,
        ...(params?.id
          ? {
              id: Number(userId?.id),
            }
          : {
              status: !!selectedSub?.name,
              office_name: User?.office?.name,
              office_id: User?.office?.id,
              suffix: val?.suffix,
              register_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
              paid_amount: val?.paid_amount?.toString(),
              amount: price_amount,
              payment_method: val?.payment_method,
              by: `${User?.user?.first_name} ${User?.user?.last_name}`,
            }),
      };

      let url = params?.id ? API.USER_UPDATE : API.USER_ADD;
      const response: any = await POST(url, {
        ...payload,
      });

      if (response?.status) {
        notification.success({
          message: "Success",
          description:
            response?.message ||
            `${params?.id ? "Successfully edited user" : "Successfully created user"}`,
        });
        navigate("/Auth/users/all");
      } else {
        notification.error({
          message: "Error",
          description:
            response?.message || "An error occurred while adding the user.",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      notification.error({
        message: "Error",
        description: "An unexpected error occurred.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getUser = async () => {
    try {
      setIsLoading(true);
      let api = API.USER_DETAILS + params?.id;
      const response: any = await GET(api, null);
      if (response?.status) {
        setUserId(response?.data?.user);
        setSubscriptionData(response?.data?.subscription);
        const gateDeviceIds = response?.data?.user?.gates?.map((gate: any) => gate.device_id);
        setIsLoading(false);
        form.setFieldsValue({
          first_name: response?.data?.user?.first_name,
          last_name: response?.data?.user?.last_name,
          prefix1: response?.data?.user?.mobile_number?.slice(0, -10),
          prefix2: response?.data?.user?.mobile_number2?.slice(0, -10),
          mobile_number: response?.data?.user?.mobile_number?.slice(-10),
          mobile_number2: response?.data?.user?.mobile_number2?.slice(-10),
          suffix: response?.data?.user?.suffix,
          email: response?.data?.user?.email,
          gender: response?.data?.user?.gender,
          birth_date:response?.data?.user?.birth_date ? moment(response?.data?.user?.birth_date) : "",
          blood: response?.data?.user?.blood,
          weight: response?.data?.user?.weight,
          height: response?.data?.user?.height,
          place: response?.data?.user?.place,
          gate: gateDeviceIds,
          type: response?.data?.user?.type,
          paid_amount: response?.data?.user?.paid_amount,
          subscription: response?.data?.user?.subscription_id,
          startDate: moment(response?.data?.user?.start_date),
          endDate: moment(response?.data?.user?.end_date),
        });
      } else {
        console.error(response?.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getUser();
    }
  }, []);


  return (
    <div>
      <PageHeaders
        title={params?.id ? "Update User" : "Create User"}
        breadcrumb={params?.id ? "Update User" : "Create User"}
      />
      <div className="screenBox">
        <Card>
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            initialValues={{
              prefix1: "+91",
              prefix2: "+91",
              type: "user",
         
              
            }}
          >
            <Row>
            <h5 className="user-heading">Basic Details</h5>
<Col md={4}>  <Form.Item
                  name="prefix"
                  label="Prefix"
                  initialValue={User?.office?.prefix}
                >
                  <Input disabled />
                </Form.Item></Col>
              <Col md={4}>
              <Form.Item name="suffix" label="Admission Number">
                  <Input disabled={params?.id ? true : false} type="number"/>
                </Form.Item>
              </Col>
              <Col md={4}>
              <Form.Item name="first_name" label="First Name" required>
                  <Input placeholder="Eg: John" />
                </Form.Item>
              </Col>
              <Col md={4}>  <Form.Item name="last_name" label="Last Name">
                  <Input placeholder="Eg: John" />
                </Form.Item></Col>
              <Col md={4}>  <Form.Item
                  name="mobile_number"
                  label="Primary Number"
                  rules={[
                    { required: true, message: "Enter Primary Number" },
                    {
                      pattern: /^\d{10}$/,
                      message: "Mobile number must be exactly 10 digits",
                    },
                  ]}
                >
                  <Input
                    addonBefore={
                      <Form.Item name="prefix1" style={{ marginBottom: -1 }}>
                        <Select
                          defaultValue="+91"
                          size="small"
                          showSearch
                          style={{ width: 75 }}
                        >
                          {CountryCode.map((item, idx) => (
                            <Select.Option value={item.dial_code} key={idx}>
                              {item.dial_code}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    }
                    placeholder="Primary Mobile Number"
                  />
                </Form.Item></Col>
              <Col md={4}><Form.Item
                  name="mobile_number2"
                  label="Secondary Mobile"
                  rules={[
                    {
                      pattern: /^\d{10}$/,
                      message: "Mobile number must be exactly 10 digits",
                    },
                  ]}
                >
                  <Input
                    addonBefore={
                      <Form.Item name="prefix2" style={{ marginBottom: -1 }}>
                        <Select
                          defaultValue="+91"
                          size="small"
                          showSearch
                          style={{ width: 75 }}
                        >
                          {CountryCode.map((item, idx) => (
                            <Select.Option value={item.dial_code} key={idx}>
                              {item.dial_code}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    }
                    placeholder="Secondary Mobile Number"
                  />
                </Form.Item></Col>
              <Col md={4}>      <Form.Item name="email" label="Email">
                  <Input type="email" placeholder="Eg: john@gmail.com" />
                </Form.Item></Col>
              <Col md={4}> <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[{ required: true, message: "Choose a Gender" }]}
                >
                  <Select placeholder="Choose a Gender">
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                  </Select>
                </Form.Item></Col>
              <Col md={4}>    <Form.Item name="birth_date" label="Date of Birth">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item></Col>
              <Col md={4}>  <Form.Item name="blood" label="Blood Group">
                  <Select placeholder="Choose Blood Group" showSearch allowClear>
                    {["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"].map(
                      (blood, idx) => (
                        <Select.Option value={blood} key={idx}>
                          {blood}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item></Col>
              <Col md={4}> 
                <Form.Item name="weight" label="Weight (KG)">
                  <Input placeholder="Eg: 60" />
                </Form.Item></Col>
              <Col md={4}><Form.Item name="height" label="Height (Cm)">
                  <Input placeholder="Eg: 170" />
                </Form.Item></Col>
              <Col md={4}>    <Form.Item
                  name="place"
                  label="Address"
                  rules={[{ required: true, message: "Choose a Address" }]}
                >
                  <TextArea rows={8} />
                </Form.Item></Col>
              <Col md={4}><Form.Item name="userImage" label="User Image">
                  <ImagePicker
                    fileURL={userId?.photo || image1?.url}
                    onChange={(val) => setImage1(val)}
                    aspectRatio={1 / 1}
                    height={"25vh"}
                  />
                </Form.Item></Col>
              <Col md={4}>  <Form.Item name="" label="ID Proof">
                  <ImagePicker
                    fileURL={userId?.id_proof || image2?.url}
                    onChange={(val) => setImage2(val)}
                    aspectRatio={1 / 1}
                    height={"25vh"}
                  />
                </Form.Item></Col>
            </Row>
         
            {!params?.id &&  <Row>
              <h5 className="user-heading">Subscription Details</h5>
              <Col md={4}><Form.Item name="subscription" label="Subscription Plan">
                    <Select
                      placeholder="Choose a Subscription Plan"
                      onChange={handleSubscriptionChange}
                      allowClear
                    >
                      {subscription?.map((item: any) => (
                        <Select.Option
                          key={item?.id}
                          id={item?.id}
                          name={item?.name}
                          price={item?.price}
                          duration_type={item?.duration_type}
                          duration={item?.duration}
                        >
                          {item.name} - {item.duration} {item.duration_type} - ₹
                          {item.price}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item></Col>
              <Col md={4}>  <Form.Item name="startDate" label="Start Date">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={start_date && moment(start_date).isValid() ? moment(start_date) : null}
                      onChange={(value) => ChangeSubDate(value, end_date, 1)}
                    />
                  </Form.Item></Col>
              <Col md={4}>  <Form.Item name="endDate" label="End Date">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={end_date && moment(end_date).isValid() ? moment(end_date) : null}
                      onChange={(value) => ChangeSubDate(start_date, value, 2)}
                    />
                  </Form.Item></Col>
              <Col md={4}>    <Form.Item
                    name="paymentMethod"
                    label="Payment Method"
             
                  >
                    <Select placeholder="Choose Payment Method" showSearch allowClear>
                      {["BANK", "UPI", "CARD", "CASH"].map((method, idx) => (
                        <Select.Option value={method} key={idx}>
                          {method}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item></Col>
              <Col md={4}> <Form.Item label="Amount">
                    <Input value={price_amount} disabled />
                  </Form.Item></Col>
              <Col md={4}>   <Form.Item label="Paying Amount" name="paid_amount">
                    <Input type="number" min={0} max={price_amount} />
                  </Form.Item></Col>
</Row>}
           
           

            <Row>
              <h5 className="user-heading">GateWays</h5>
              <Col md={4}>
                <Form.Item
                     name="gate"
                     label="GateWay"
                     rules={[{ required: true, message: "Choose GateWay" }]}>
                  <Select
                       placeholder="Choose GateWay"
                       mode="multiple" 
                       showSearch allowClear >
                       {User?.office?.devices?.map((item: any, idx: number) => (
                 <Select.Option value={item?.device_id} key={idx}>
                       {item?.device_name}
                 </Select.Option>
                             ))}
                    </Select>
             </Form.Item>
              </Col>
            </Row>
            <Row>

              <h5 className="user-heading">Account Type</h5>
              <Col md={4}>
                <Form.Item
                  name="type"
                  label="Account Type"
                  rules={[
                    { required: true, message: "Choose an Account Type" },
                  ]}
                >
                  <Select
                    placeholder="Choose an Account Type"
                    onChange={handleAccountTypeChange}
                    allowClear
                    // defaultValue={"user"}
                    
                  >
                    <Select.Option value="user">User</Select.Option>
                    <Select.Option value="trainer">Trainer</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              {accountType === "trainer" && (
                <Col md={4}>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true, message: "Enter a Password" }]}
                  >
                   <Input.Password />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col></Col>
              <Col sm={4}>
                <Button size="large" danger block onClick={() => navigate(-1)}>
                  Close
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  size="large"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isLoading}
                >
                  {params?.id ? "Update" : "Submit"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default CreateBooking;
