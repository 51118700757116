import { Alert, Button, Modal } from "antd";
import { Col, Row } from "react-bootstrap";

const CustomModal = (props: any) => {
  return (
    <Modal
      open={props?.isModalOpen}
      onCancel={props?.handleCancel}
      footer={null}
      closable={false}
      centered
    >
      <div>
        <Alert
          message={`${props?.type} ${props?.title}`}
          description={`Are you sure you want to ${props?.type} this ${props?.title}?`}
          type="warning"
          showIcon
          className="mb-4"
        />

        <Row>
          <Col md={{ span: 7, offset: 5 }}>
            <div className="d-flex gap-2">
              <Button block onClick={props?.handleCancel} className="me-2">
                Cancel
              </Button>
              <Button block danger onClick={props?.onDelete} >
                Yes, {props?.type}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default CustomModal;
