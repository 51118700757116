import "./styles.scss";

function HorizontalLineLoader() {
  return (
    <div>
      <div className="loader">
        <div className="fixed-line" style={{ backgroundColor: "#FFC127" }} />
        <div className="moving-line" style={{ backgroundColor: "#08493e" }} />
      </div>
    </div>
  );
}

export default HorizontalLineLoader;
