import { Button, Card, DatePicker, Form, Input, message, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import PageHeaders from "../../components/pageHeaders";
import { API } from "../../config/Api";
import { POST } from "../../utils/apiRequest";
import DataTable from "./dataTable";
import LoadingBox from "../../components/loadingBox";

const Attendance: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const User = useSelector((state: any) => state?.User?.user);

  const [searchState, setSearchState] = useState({
    query: "",
    page: 1,
    pageSize: 20,
    attendanceStatus: "Presents",
  });

  const getAttendance = async () => {
    try {
      setLoading(true);

      const { query, attendanceStatus } = searchState;
      const date = moment(selectedDate).format("YYYY-MM-DD");
      const month = moment(selectedDate).month() + 1;
      const year = moment(selectedDate).format("YYYY");

      const body = {
        id: User?.office?.id,
        filter: attendanceStatus,
        search: query,
        date,
        month,
        year,
      };

      const response: any = await POST(API.ATTENDANCE, body);

      if (response?.status) {
        setAttendance(response?.data || []);
        message.success("Attendance fetched successfully");
      } else {
        message.error(response.data?.message || "Failed to fetch attendance");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while fetching attendance");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAttendance();
  }, [selectedDate, searchState]);

  const clearFilters = () => {
    setSearchState({
      query: "",
      page: 1,
      pageSize: 20,
      attendanceStatus: "Presents",
    });
    form.resetFields(["query", "attendanceStatus"]);
  };

  const handleSearchChange = (e: any) => {
    setSearchState((prev) => ({ ...prev, query: e.target.value, page: 1 }));
  };

  const handleDateChange = (date: moment.Moment | null) => {
    setSelectedDate(date ? date.toDate() : new Date());
  };

  const handleStatusChange = (value: string) => {
    setSearchState((prev) => ({ ...prev, attendanceStatus: value, page: 1 }));
  };

  return (
    <div>
      <PageHeaders title="Attendance" breadcrumb="Attendance">
        <Form
          form={form}
          layout="inline"
          initialValues={{
            attendanceStatus: "Presents",
            date: moment(selectedDate),
          }}
        >
          <div className="d-flex flex-column flex-wrap flex-sm-row gap-2">
            <Form.Item name="query">
              <Input
                style={{ width: 200 }}
                placeholder="Search name or phone etc ..."
                allowClear
                suffix={<CiSearch size={16} />}
                onChange={handleSearchChange}
              />
            </Form.Item>

            <Form.Item name="date">
              <DatePicker
                style={{ width: 200 }}
                onChange={handleDateChange}
                format="DD-MM-YYYY"
              />
            </Form.Item>

            <Form.Item name="attendanceStatus">
              <Select style={{ width: 200 }} onChange={handleStatusChange}>
                <Select.Option value="Presents">Present</Select.Option>
                <Select.Option value="Absents">Absent</Select.Option>
              </Select>
            </Form.Item>

            <Button onClick={clearFilters}>Clear Filters</Button>
          </div>
        </Form>
      </PageHeaders>
      <div className="screenBox">
        <Card>
          <h5 className="user-heading">
            {searchState?.attendanceStatus} ({attendance?.length})
          </h5>
          {loading ? (
            <LoadingBox />
          ) : (
            <DataTable
              data={attendance}
              filter={searchState?.attendanceStatus}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default Attendance;
